import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { AbsenceType } from "../my-time/absence/models/absence-type.model";
import { selectAbsenceTypes } from "../state/time-common/selectors/time-common.selectors";


@Pipe({
    name: 'disallowBalanceAllocation',
    pure: false,
    standalone: true
})
export class DisallowBalanceAllocationPipe implements PipeTransform {
  absenceTypes: AbsenceType[];

  constructor(private store: Store) {
    this.store.select(selectAbsenceTypes).subscribe((absenceTypes) => this.absenceTypes = absenceTypes);
  }

  transform(abscenceTypeId: number | null): boolean {
    if(abscenceTypeId == null) return false;
    var absenceType = this.absenceTypes?.find(f => f.OrganizationAbsenceMapId === abscenceTypeId && f.AllowBalanceAllocation);
    if (!absenceType) return false;
    return true;
  }
}
