import { FormGroup } from "@angular/forms";
import { MentorAdminSystemMessageModel, MentorAdminSystemMessageTextModel } from "@mentor-one-ui/core/models/mentor-admin-system-messages/mentor-admin-system-message.model";
import { createAction, props } from "@ngrx/store";

export const SystemMessagesActions = {
  Enter: createAction('[System Messages] Enter'),
  LoadSystemMessagesSuccess: createAction('[System Messages] Load System Messages Success', props<{ list: MentorAdminSystemMessageModel[] }>()),
  LoadSystemMessagesFailure: createAction('[System Messages] Load System Messages Failure', props<{ error: string }>()),
  GetSelectedSystemMessage: createAction('[System Messages] Get Selected System Message'),
  GetSelectedSystemMessageSuccess: createAction('[System Messages] Get Selected System Message Success', props<{ message: MentorAdminSystemMessageModel }>()),
  GetSelectedSystemMessageFailure: createAction('[System Messages] Get Selected System Message Failure', props<{ error: string }>()),
  UpdateSystemMessage: createAction('[System Messages] Update System Message', props<{ systemMessage: MentorAdminSystemMessageModel }>()),
  UpdateSystemMessageSuccess: createAction('[System Messages] Update System Message Success', props<{ systemMessage: MentorAdminSystemMessageModel }>()),
  UpdateSystemMessageFailure: createAction('[System Messages] Update System Message Failure', props<{ error: string }>()),
  OpenEditSystemMessageTextModal: createAction('[System Messages] Edit System Message Text', props<{ texts: MentorAdminSystemMessageTextModel }>()),
  DeleteSystemMessage: createAction('[System Messages] Delete System Message', props<{ id: number }>()),
  DeleteSystemMessageSuccess: createAction('[System Messages] Delete System Message Success', props<{ id: number }>()),
  DeleteSystemMessageFailure: createAction('[System Messages] Delete System Message Failure', props<{ error: string }>()),
};
