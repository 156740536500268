import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TimeManagementStatusEnum } from '../../models/TimeManagementStatusEnum';
import { CommonModule } from '@angular/common';

import { TagModule } from 'primeng/tag';
import { TranslatePipe } from '../../../../../core/pipes/translate.pipe';

@Component({
  selector: 'mo-absence-status-tag',
  styleUrls: ['./absence-status-tag.component.scss'],
  standalone: true,
  imports: [CommonModule, TagModule, TranslatePipe],
  template: `
    <ng-container [ngSwitch]="status">
      <p-tag
        *ngSwitchCase="0"
        value="{{ 'unprocessed' | translate | async }}"
        severity="info"
        icon="fal fa-hourglass"
      ></p-tag>
      <p-tag *ngSwitchCase="1" value="{{ 'approved' | translate | async }}" icon="fal fa-check"> </p-tag>
      <p-tag
        *ngSwitchCase="2"
        value="{{ 'declined' | translate | async }}"
        severity="danger"
        icon="fal fa-times-circle"

      ></p-tag>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsenceStatusTagComponent implements OnInit {
  @Input() status: TimeManagementStatusEnum | number | null;

  constructor() {}

  ngOnInit(): void {}
}
