<br>
<mo-employee-avatar-name [employeeId]="employeeId"></mo-employee-avatar-name>
<br>
<p-tabs value="0">
  <p-tablist>
    <p-tab value="0">{{ 'absence' | translate | async }}</p-tab>
    <p-tab value="1" *ngIf="(showTimeForTimeOffWarning$ | async)">{{ 'overtime' | translate | async }}</p-tab>
  </p-tablist>
  <p-tabpanels>
    <p-tabpanel value="0">
      <div class="pbl-grid pbl-grid-small available-years">
        <p-select [options]="availableYears" [(ngModel)]="selectedYear" (onChange)="onYearChange($event)"></p-select>
      </div>
      <div class="admin-balance">
        <ng-container *ngIf="(adminBalances$ | async) as adminBalances;">
          <p-table [value]="adminBalances" [tableStyle]="{ 'min-width': '30rem' }" class="balance-grid" showGridlines>
            <ng-template pTemplate="header">
              <tr>
                <th class="desktop"></th>
                <th>{{'allotted' | translate | async}}</th>
                <th>{{'transfered-from' | translate | async}} {{previousYear}}</th>
                <th>{{'used' | translate | async}}</th>
                <th>{{'planned' | translate | async}}</th>
                <th>{{'time-left' | translate | async}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-b>
              <tr class="type-name mobile">
                <td [attr.colspan]="5" class="type-title">
                  <mo-emoji [emojiCode]="b.TypeEmoji" [rounded]="false"></mo-emoji>
                  <span>{{b.TypeTitle}}</span>
                </td>
              </tr>
              <!-- <tr> -->
              <tr>
                <td class="type-title desktop">
                  <mo-emoji [emojiCode]="b.TypeEmoji" [rounded]="false"></mo-emoji>
                  <span>{{b.TypeTitle}}</span>
                </td>
                <td class="edit-column">
                  <div class="flex-space">
                    <span *ngIf="!(b.OrganizationAbsenceMapId | disallowBalanceAllocation) && b.BalanceDisplayType == 0">{{b.AllocatedDays}}</span>
                    <p-inputNumber
                      *ngIf="b.OrganizationAbsenceMapId != null && (b.OrganizationAbsenceMapId | disallowBalanceAllocation)"
                       (onInput)="canSave(adminBalances)"
                      inputStyleClass="inputtext-sm" inputId="integeronly" [(ngModel)]="b.AllocatedDays">
                    </p-inputNumber>
                    <span *ngIf="b.OrganizationAbsenceMapId != null && (b.OrganizationAbsenceMapId | disallowBalanceAllocation)" class="desktop">
                      {{'days' | translate | async}}
                    </span>
                  </div>
                </td>
                <td class="edit-column">
                  <div class="flex-space">
                    <span *ngIf="!(b.OrganizationAbsenceMapId | disallowBalanceAllocation) &&
                      b.BalanceDisplayType == 0 &&
                      b.AbsenceTypeCode != TimeManagementTypeCode.CaredaysSickChild">
                      {{b.TransferDaysIn ?? 0}}
                    </span>
                    <p-inputNumber
                      *ngIf="b.OrganizationAbsenceMapId != null &&
                        (b.OrganizationAbsenceMapId | disallowBalanceAllocation) &&
                        b.AbsenceTypeCode != TimeManagementTypeCode.CaredaysSickChild"
                      (onInput)="canSave(adminBalances)"
                      inputStyleClass="inputtext-sm" inputId="integeronly" [(ngModel)]="b.TransferDaysIn">
                    </p-inputNumber>
                    <span *ngIf="b.OrganizationAbsenceMapId != null &&
                      (b.OrganizationAbsenceMapId | disallowBalanceAllocation) &&
                      b.AbsenceTypeCode != TimeManagementTypeCode.CaredaysSickChild"
                       class="desktop">
                      {{'days' | translate | async}}
                    </span>
                  </div>
                </td>
                <td>
                  <span *ngIf="b.BalanceDisplayType == 2 || b.BalanceDisplayType == 0">
                    {{b.UsedDays}} {{'days' | translate | async}}
                  </span>
                </td>
                <td><span *ngIf="b.BalanceDisplayType == 0">{{b.PlannedDays}} {{'days' | translate | async}}</span></td>
                <td>
                  <span *ngIf="b.BalanceDisplayType == 0">{{b.CurrentBalance}} {{'days' | translate | async}}</span>
                  <span *ngIf="b.BalanceDisplayType == 1">{{b.CurrentBalanceMinutes | minutesToHours}}</span>
                </td>
              </tr>
              <tr class="comment-row"
                *ngIf="(b.AllocatedDays > -1 && b.AllocatedDays != b.OrgVal) || (b.Comment != null && b.Comment != '')">
                <td class="desktop"></td>
                <td [attr.colspan]="5">
                  <div class="item-comment">
                    <div>
                      <label>{{'why-is-number-changed' | translate | async}}</label>
                      <input type="text" pInputText [(ngModel)]="b.Comment"
                        (keyup)="canSave(adminBalances)" maxlength="4000" />
                    </div>
                  </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
          <ng-template pTemplate="body" #skeleton>
            <tr>
              <td><p-skeleton></p-skeleton></td>
              <td><p-skeleton></p-skeleton></td>
              <td><p-skeleton></p-skeleton></td>
              <td><p-skeleton></p-skeleton></td>
              <td><p-skeleton></p-skeleton></td>
            </tr>
          </ng-template>

          <mo-collapsable-info-panel [collapsed]="true"
            header="{{'editAdminiBalanceAllocationHelpTitle' | translate | async}}"
            infoText="{{'editAdminiBalanceAllocationHelpText' | translate | async}}">
          </mo-collapsable-info-panel>
        </div>
    </p-tabpanel>
    <p-tabpanel value="1">
      <div class="overtime-allocation">
        <label>{{'compensatory-time-off' | translate | async}}</label>
        <div
          class="panel"
          [ngClass]="{'active-add': compensatoryTimeOffDirection === 'add', 'active-subtract': compensatoryTimeOffDirection === 'subtract'}">
            <p-selectButton
            [options]="compensatoryTimeOffDirectionOptions"
            [(ngModel)]="compensatoryTimeOffDirection"
            optionValue="value">
            <ng-template let-item #item>
              <i class="pi pi-circle"
                [ngClass]="{'pi-check-circle': compensatoryTimeOffDirection === item.value}">
              </i>

              <span class="label">{{item.label}} (<i [class]="item.icon"></i>)</span>

            </ng-template>
          </p-selectButton>

          <div class="add" *ngIf="compensatoryTimeOffDirection">
            <div class="time">
              <div class="pbl-input-text">
                <label>{{'hours' | translate | async}}</label>
                <p-inputNumber [(ngModel)]="compensatoryTimeOffHour" inputId="integeronly">
                </p-inputNumber>
              </div>
              <div class="pbl-input-text">
                <label>{{'minutes' | translate | async}}</label>
                <p-inputNumber [(ngModel)]="compensatoryTimeOffMinutes" inputId="integeronly">
                </p-inputNumber>
              </div>
            </div>
            <div class="pbl-input-text">
              <label>{{'compensatory-time-off-comment' | translate | async}}</label>
              <input type="text" pInputText [(ngModel)]="compensatoryTimeOffComment" maxlength="250" />
            </div>
            <div class="add-button">
              <button pButton type="button" label="{{'save' | translate | async}}"
                (click)="saveCompensatoryTimeOff()"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="overtime-allocation-list">
        <p-table [value]="(overtimeAllocations | async)!">
          <ng-template pTemplate="header">
            <tr>
              <th>{{'date' | translate | async}}</th>
              <th>{{'time' | translate | async}}</th>
              <th>{{'comment' | translate | async}}</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-o>
            <tr>
              <td>{{o.CreatedDate | date: 'dd.MM.yyyy'}}</td>
              <td>{{getTime(o.Minutes)}}</td>
              <td>{{o.Comment}}</td>
              <td class="right">
                <button pButton type="button" class="p-button-danger" icon="fal fa-trash-alt"
                  (click)="deleteOvertimeAllocation($event, o)"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabpanel>
  </p-tabpanels>
</p-tabs>
<p-confirmdialog />
<mo-default-buttons
  [showSave]="activeIndex == 0"
  [showDelete]="false"
  [canSave]="btnCanSave"
  [isSaving]="(isSaving$ | async)!"
  (cancel)="goBack()"
  (save)="save()">
</mo-default-buttons>
