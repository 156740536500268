import { Injectable } from '@angular/core';
import { UserActions } from '@mentor-one-ui/core/state/user/user.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';
import { AbsenceService } from '../my-time/absence/absence.service';
import { AbsenceApiActions } from '../my-time/absence/actions/absence-api.actions';
import { AbsenceType } from '../my-time/absence/models/absence-type.model';
import { OvertimeApiActions } from '../my-time/overtime/actions/overtime-api.actions';
import { OvertimeType } from '../my-time/overtime/models/overtime-type.model';
import { OvertimeService } from '../my-time/overtime/overtime.service';

@Injectable()
export class AbsenceTypesEffects {
  constructor(
    private absenceService: AbsenceService,
    private overtimeService: OvertimeService,
    private actions$: Actions
  ) {}

  loadAbsenceTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.bootstrapApplicationData),
      switchMap((action) =>
        this.absenceService.getAbsenceTypes().pipe(
          map((absenceTypes: AbsenceType[]) => {
            return AbsenceApiActions.loadAbsenceTypesSuccess({
              absenceTypes: absenceTypes,
            });
          }),
          catchError((e) => of(AbsenceApiActions.loadAbsenceTypesError(e.message)))
        )
      )
    );
  });

  loadOvertimeTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.bootstrapApplicationData, OvertimeApiActions.registerOvertimeCollectionSuccess),
      switchMap((action) =>
        this.overtimeService.getOvertimeTypes().pipe(
          map((overtimeTypes: OvertimeType[]) => {
            return OvertimeApiActions.loadOvertimeTypesSuccess({
              overtimeTypes,
            });
          }),
          catchError((e) => of(OvertimeApiActions.loadOvertimeTypesError(e.message)))
        )
      )
    );
  });
}
