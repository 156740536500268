import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSetPassword from './set-password.reducer';
import { selectQueryToken } from '@mentor-one-ui/core/state/router/router.selectors';
import { jwtDecode } from 'jwt-decode';

const selectApplicationState = createFeatureSelector<fromSetPassword.State>('setpassword');

export const selectSelectedToken = createSelector(
  selectQueryToken,
  (token) => {
    return token;
  });

const selectSelectedTokenOrganization = createSelector(
  selectSelectedToken,
  (token: any) => {
    try {
      const decoded = jwtDecode(token!);
      let stringify = JSON.parse(JSON.stringify(decoded));
      return stringify.organization;
    } catch (error: any) {
      return null;
    }
  });

const selectSelectedTokenExpDate = createSelector(
  selectSelectedToken,
  (exp: any) => {
    try {
      const decoded = jwtDecode(exp!);
      let stringify = JSON.parse(JSON.stringify(decoded));
      let date = parseInt(stringify.exp);
      return date;
    } catch (error: any) {
      return null;
    }
  });


const selectPasswordViewModel = createSelector(
  selectApplicationState,
  selectSelectedToken,
  selectSelectedTokenExpDate,
  (state, token, exp) => {

    let dateError: boolean = false;
    let expDateString: string = '';

    if (exp != null) {

      let expDate = new Date(exp! * 1000);

      if (expDate < new Date()) {
        dateError = true;
      } else {
        expDateString = expDate.toLocaleDateString(['nb-NO'], { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
    }

    return {
      dateError: dateError,
      showPasswordForm: dateError ? false : state.showPasswordForm,
      tokenStateIsLoading: state.tokenStateIsLoading,
      isTokenUsed: state.isTokenUsed,
      token: token,
      expDate: expDateString,
      showConfirmBox: state.confirmbox
    }
  }
);

const selectShowServerError = createSelector(
  selectApplicationState,
  state => state.showServerErrorMessage
);

const selectTokenIsCompleted = createSelector(
  selectApplicationState,
  state => state.tokenStateIsLoading
);



export const SetPasswordSelectors = {
  selectShowSetPasswordForm: selectPasswordViewModel,
  selectShowServerError,
  selectTokenIsCompleted,
  selectSelectedTokenOrganization,
  selectSelectedTokenExpDate
}




