import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { EmployeeService } from '@mentor-one-ui/core/services/api/employee.service';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { UserTokenModel } from '@mentor-one-ui/core/state/user/user.model';
import { UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';
import { EmployeeDetailsApiActions, EmployeeModalActions } from '@mentor-one-ui/employees/state/actions/employee-details.actions';
import { EmployeePageActions } from '@mentor-one-ui/employees/state/actions/employee-page.actions';
import { Store } from '@ngrx/store';
import { ImageCroppedEvent, LoadedImage, ImageCropperModule } from 'ngx-image-cropper';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageModule } from 'primeng/message';
import { Subject, catchError, of, throwError } from 'rxjs';
import { NgIf, AsyncPipe } from '@angular/common';
import { DefaultButtonsComponent } from '../../../core/components/default-buttons/default-buttons.component';
import { TranslatePipe } from '../../../core/pipes/translate.pipe';
import { ConfirmDialog } from 'primeng/confirmdialog';

@Component({
    selector: 'app-picture-editor',
    template: `
  <div class="profile-picture">
      <input type="file" (change)="fileChangeEvent($event)" accept="image/jpeg" class="profile-picture__file" />
      <h3 *ngIf="showEditor && isWrongFormat == false" class="u-text-center">{{ 'crop-photo' | translate | async }}</h3>
      <p-message *ngIf="isWrongFormat" severity="error"  styleClass="mb-2" icon="pi pi-times-circle">{{ 'invalid-picture-format' | translate | async}}</p-message>
      <div *ngIf="isWrongFormat != true" class="profile-picture__editor">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [roundCropper]="true"
          [aspectRatio]="1 / 1"
          format="jpeg"
          [imageFile]= "imageFile"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded($event)"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
          class="profile-picture__editorInput"
        ></image-cropper>
        <span *ngIf="showEditor"><i class="fal fa-arrow-right"></i></span>
        <img [src]="croppedImage" class="profile-picture__editorOutput" />
      </div>
    </div>
    <mo-default-buttons
      [showDelete]="showDelete"
      [showSave]="showSave"
      [isSaving]="isSaving"
      (delete)="deletePicture()"
      (cancel)="cancelPhotoModal()"
      (save)="savePhoto()"
    ></mo-default-buttons>
    <p-confirmdialog />
  `,
    styleUrls: ['./picture-editor.component.scss'],
    standalone: true,
    imports: [NgIf, ImageCropperModule, DefaultButtonsComponent, MessageModule, AsyncPipe, TranslatePipe, ConfirmDialog],
    providers: [ConfirmationService],
})
export class PictureEditorComponent implements OnInit, OnDestroy {
  public ngDestroyed$ = new Subject();
  imageChangedEvent: ImageCroppedEvent;
  croppedImage: any;
  showEditor: boolean = false;
  isWrongFormat: boolean = false;
  imageFile: File;
  employee: EmployeeModel;
  isSaving: boolean;
  showDelete: boolean = false;
  showSave: boolean = true;
  userToken: UserTokenModel | undefined;

  constructor(private store: Store,
    private employeeService: EmployeeService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private translationService: TranslationDataService,
    private messageService: MessageService,
    public config: DynamicDialogConfig) {

    this.employee = config?.data?.employee;
  }
  ngOnInit(): void {
    this.store.select(UserSelectors.selectUserToken).subscribe(token => {
      this.userToken = token;
    });

    if(this.employee?.HasProfilePicture == true) {
      this.employeeService.getEmployeeProfilePicture(this.employee.EmployeeId).subscribe(dataUrl => {
        this.imageFile = dataUrl;
      });
    }
  }

  ngOnDestroy(): void {
    this.croppedImage = null;
    this.ngDestroyed$.next(null);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.isWrongFormat = false;
    this.showSave = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.objectUrl;
  }

  imageLoaded(image: LoadedImage) {
    this.showEditor = true;
    this.showDelete = true;
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this.isWrongFormat = true;
    this.showDelete = false;
    this.showSave = false;
  }
  cancelPhotoModal() {
    this.store.dispatch(EmployeeModalActions.CloseEmployeePictureEditorModal());
  }

  deletePicture() {
    this.confirmationService.confirm({
      message: this.translationService.translate('delete-description'),
      header: this.translationService.translate('delete'),
      acceptLabel: this.translationService.translate('delete'),
      rejectLabel: this.translationService.translate('cancel'),
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary',
      acceptIcon: 'fal fa-trash-alt',
      accept: () => {
        this.store.dispatch(EmployeeDetailsApiActions.DeleteMyProfilePicture({ employeeId: this.employee.EmployeeId }));
        this.croppedImage = '';
        this.showEditor = false;
        this.showDelete = false;
        this.store.dispatch(EmployeeModalActions.CloseEmployeePictureEditorModal());
      },
      reject: () => { },
    });
  }

  async savePhoto() {
    let imageFile = await dataUrlToFile(this.croppedImage, 'profileimage');
    this.isSaving = true;

    this.employeeService.saveEmployeeProfilePicture(this.employee.EmployeeId, imageFile)
      .pipe(catchError((r) => {
        this.isSaving = false;
        this.isWrongFormat = true;

        return throwError(() => r);
      }))
      .subscribe({
        next: (v) => {
          this.store.dispatch(EmployeePageActions.SetProfilePicture({ image: this.croppedImage, employeeId: this.employee.EmployeeId }));
          this.store.dispatch(EmployeeModalActions.CloseEmployeePictureEditorModal());
        },
        error: (e) => {
          this.messageService.add({
            key: 'error',
            severity: 'error',
            summary: 'Feil ved opplasting',
            detail: 'En feil oppstod ved opplasting av bilde',
            life: 5000,
          });
        },
        complete: () => this.isSaving = false
      });
  }
}

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/jpeg' });
}
