import { createReducer, on } from '@ngrx/store';
import { contactApiActions } from './actions/contact-api.actions';
import { contactPageActions } from './actions/contact-page.actions';
import { ContactModel } from '@mentor-one-ui/core/models/user-organization/contact/ContactModel';
import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureKey = 'contacts';

export interface State extends EntityState<ContactModel> {
  contactModalOpen: boolean;
  selectedContactId: number | null;
  filterTag: string;
  isLoading: boolean;
  isSaving: boolean;
  loadError: boolean;
}

export const adapter: EntityAdapter<ContactModel> = createEntityAdapter<ContactModel>({
  sortComparer: false,
  selectId: (s) => s.ContactId
})

export const initialState: State = adapter.getInitialState({
  contactModalOpen: false,
  selectedContactId: null,
  filterTag: '',
  isLoading: false,
  isSaving: false,
  loadError: false,
})

export const reducer = createReducer<State>(
  initialState,
  on(contactPageActions.enter, (state, action): State => {
    return {
      ...state,
      isLoading: true,
      loadError: false,
    };
  }),
  on(contactPageActions.openContactModal, (state, action): State => {
    return {
      ...state,
      contactModalOpen: true,
    };
  }),
  on(contactPageActions.editContact, (state, action): State => {
    return {
      ...state,
      selectedContactId: action.payload,
    };
  }),
  on(contactPageActions.editNewContact, (state, action): State => {
    return {
      ...state,
      selectedContactId: null,
    };
  }),
  on(contactApiActions.loadContactsSuccess, (state, action): State => {
    return adapter.setAll(action.contact, {
      ...state,
      loadError: false,
      isLoading: false
    })
  }),
  on(contactApiActions.loadContactsError, (state, action): State => {
    return {
      ...state,
      loadError: true,
      isLoading: false,
    };
  }),
  on(contactApiActions.deleteContactSuccess, (state, action): State => {
    return adapter.removeOne(action.contactId, {...state, isSaving: false});
  }),
  on(contactApiActions.saveContact, (state, action): State => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(contactApiActions.saveContactSuccess, (state, { contact }): State => {
    return adapter.upsertOne(contact, {...state,  loadError: false, isSaving: false})
  }),
  on(contactApiActions.saveContactError, (state, action): State => {
    return {
      ...state,
      loadError: true,
      isSaving: false
    }
  }),
  on(contactPageActions.setFilterTag, (state, action): State => {
    return {
      ...state,
      filterTag: action.payload,
    };
  }),
);
