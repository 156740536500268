import { Injectable } from "@angular/core";
import { TranslationDataService } from "@mentor-one-ui/core/services/translation-data.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { catchError, map, of, switchMap } from "rxjs";
import { AdminBalanceApiActions } from "../../actions/admin-balance-api.actions";
import { AdminBalancePageActions } from "../../actions/admin-balance-page.actions";
import { EmployeeBalanceDetailComponent } from "../../components/employee-balance-detail/employee-balance-detail.component";
import { AdminBalanceModel, OvertimeAllocationModel } from "../../models/admin-balance.model";
import { AdminBalanceService } from "../../services/balance.service";
import { selectFilter, selectSelectableYears, selectSelectedTypeType } from "../selectors";
import { TimeManagementType } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementType";
import { selectTimeTypes } from "@mentor-one-ui/time/state/time-common/selectors/timeTypeSelectors";
import { EmployeeBalanceDetailPageActions } from "../../components/employee-balance-detail/actions/employee-balance-detail.page.actions";
import { EmployeeBalanceDetailApiActions } from "../../components/employee-balance-detail/actions/employee-balance-detail.api.actions";
import { MessageService } from "primeng/api";
import { ApplicationActions } from "@mentor-one-ui/core/state/application/application.actions";

@Injectable()
export class AdminBalanceEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private translationService: TranslationDataService,
    private dialogService: DialogService,
    private adminBalanceService: AdminBalanceService,
    private messageService: MessageService
  ) { }

  dialogRef: DynamicDialogRef;
  loadAdminBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminBalancePageActions.filterByYear,
        AdminBalancePageActions.filterByTimeType,
        AdminBalanceApiActions.addOvertimeAllocationSuccess,
        AdminBalanceApiActions.deleteOvertimeAllocationSuccess,
        AdminBalancePageActions.resetFilter),
      concatLatestFrom(() => [
        this.store.select(selectFilter),
        this.store.select(selectSelectedTypeType),
        this.store.select(selectTimeTypes)
      ]),
      switchMap(([action, filter, selectedType, types]) => {
        let absenceTypeId: number | undefined;
        let overtimeTypeId: number | undefined;


        if(!selectedType) {
          selectedType = {
            id: types[0]?.timeTypeId,
            timeManagementType: types[0]?.timeManagementType,
            balanceDisplayType: types[0]?.balanceDisplayType
          };
        }
        if(selectedType.timeManagementType === TimeManagementType.Absence) {
            absenceTypeId = selectedType.id;
        } else {
          overtimeTypeId = selectedType.id;
        }

        return this.adminBalanceService.loadAdminBalance(filter.selectedYear, absenceTypeId, overtimeTypeId!).pipe(
          map((result: AdminBalanceModel[]) => {
            return AdminBalanceApiActions.loadAdminBalanceSuccess({
              balances: result,
            });
          }),
          catchError((e) => of(AdminBalanceApiActions.loadAdminBalanceError(e.message)))
        )
      })
    )
  );

  openEmployeeModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminBalancePageActions.OpenEmployeeModal),
      concatLatestFrom(() => [
        this.store.select(selectFilter),
        this.store.select(selectSelectableYears)
      ]),
      switchMap(([action, filter, years]) => {
        this.dialogRef = this.dialogService.open(EmployeeBalanceDetailComponent, {
          data: {
            employeeId: action.employeeId,
            availableYears: years,
            selectedYear: filter.selectedYear,
          },
          header: this.translationService.translate('edit-allotted-time'),
          closeOnEscape: true,
          closable: true,
          modal: true,
          focusTrap: true,
        });

        return this.dialogRef.onClose;
      })
    ),
    { dispatch: false }
  );

  closeEmployeeModal$ = createEffect(() =>
    this.actions$.pipe(ofType(
      EmployeeBalanceDetailApiActions.updateEmployeeBalanceSuccess,
      AdminBalancePageActions.CloseEmployeeModal),
      map((payload) => {
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      })
    ),
    { dispatch: false }
  );

  loadEmployeeBalanceDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminBalancePageActions.OpenEmployeeModal),
      concatLatestFrom(() => [
        this.store.select(selectFilter),
      ]),
      switchMap(([action, filter]) =>
        this.adminBalanceService.getBalancesForEmployee(action.employeeId, filter.selectedYear).pipe(
          map((adminBalance: AdminBalanceModel[]) => {
            return AdminBalanceApiActions.loadEmployeeBalanceDetailsSuccess({ balance: adminBalance });
          }),
          catchError((e) => of(AdminBalanceApiActions.loadEmployeeBalanceDetailsError(e.message)))
        )
      )
    );
  });

  loadEmployeeOvertimeAllocationDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminBalancePageActions.OpenEmployeeModal),
      switchMap((action) =>
        this.adminBalanceService.getOvertimeAllocationsForEmployee(action.employeeId).pipe(
          map((overtimeAllocations: OvertimeAllocationModel[]) => {
            return AdminBalanceApiActions.loadEmployeeOvertimeAllocationsSuccess({ allocations: overtimeAllocations });
          }),
          catchError((e) => of(AdminBalanceApiActions.loadEmployeeBalanceDetailsError(e.message)))
        )
      )
    );
  });

  addEmployeeOvertimeAllocationDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminBalanceApiActions.addOvertimeAllocation),
      switchMap((action) =>
        this.adminBalanceService.addOvertimeAllocationsForEmployee(action.employeeId, action.minutes, action.comment).pipe(
          map((overtimeAllocation: OvertimeAllocationModel) => {
            return AdminBalanceApiActions.addOvertimeAllocationSuccess({ allocation: overtimeAllocation});
          }),
          catchError((e) => of(AdminBalanceApiActions.addOvertimeAllocationError(e.message)))
        )
      )
    );
  });

  deleteEmployeeOvertimeAllocationDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminBalanceApiActions.deleteOvertimeAllocation),
      switchMap((action) =>
        this.adminBalanceService.deleteOvertimeAllocationsForEmployee(action.employeeId, action.id).pipe(
          map(() => {
            return AdminBalanceApiActions.deleteOvertimeAllocationSuccess({ allocationId: action.id });
          }),
          catchError((e) => of(AdminBalanceApiActions.addOvertimeAllocationError(e.message)))
        )
      )
    );
  });

  overtimeAllocationAddedSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminBalanceApiActions.addOvertimeAllocationSuccess),
      map((action) => {
        return ApplicationActions.SuccessMessage({
          title: this.translationService.translate('success'),
          message: this.translationService.translate('overtime-allocation-added'),
        });
      })
    );
  });

  overtimeAllocationDeletedSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminBalanceApiActions.deleteOvertimeAllocationSuccess),
      map((action) => {
        return ApplicationActions.SuccessMessage({
          title: this.translationService.translate('success'),
          message: this.translationService.translate('overtime-allocation-deleted'),
        });
      })
    );
  });

  loadEmployeeBalanceDetailsBasedOnYear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeBalanceDetailPageActions.ChangeYear),
      switchMap((action) =>
        this.adminBalanceService.getBalancesForEmployee(action.employeeId, action.year).pipe(
          map((adminBalance: AdminBalanceModel[]) => {
            return EmployeeBalanceDetailApiActions.ChangeYearSuccess({ balances: adminBalance });
          }),
          catchError((e) => of(EmployeeBalanceDetailApiActions.ChangeYearSuccess(e.message)))
        )
      )
    );
  });

  updateEmployeeBalanceDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminBalancePageActions.updateEmployeeBalance),
      switchMap((action) =>
        this.adminBalanceService.updateEmployeeBalanceAllocation(action.employeeId, action.updatemodel).pipe(
          map((adminBalance: AdminBalanceModel[]) => {
            let newBalances = adminBalance.map((b) => {
              return { ...b, Year: action.updatemodel.Year }
            });
            return EmployeeBalanceDetailApiActions.updateEmployeeBalanceSuccess({ balances: newBalances });
          }),
          catchError((e) => of(EmployeeBalanceDetailApiActions.updateEmployeeBalanceError({ error: this.translationService.translate('could-not-update-balance')})))
        )
      )
    );
  });

  displayErrorToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EmployeeBalanceDetailApiActions.updateEmployeeBalanceError
      ),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('something-went-wrong'),
          message: action.error,
        });
      })
    );
  });

  updateEmployeeBalanceSuccessToat$ = createEffect(() =>
    this.actions$.pipe(ofType(EmployeeBalanceDetailApiActions.updateEmployeeBalanceSuccess),
      map((payload) => {
        this.messageService.add({
          severity: 'success',
          summary: this.translationService.translate('saved'),
          detail: this.translationService.translate('allocation-saved')
        });
      })
    ),
    { dispatch: false }
  );
}
