import { createReducer, on } from '@ngrx/store';
import { ManageApiActions } from '../actions/manage-api.actions';
import { ManageEditAbsencePageActions } from '../../containers/manage-edit-absence-page/actions/manage-edit-absence-page.actions';
import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';
import { ManageEditOvertimePageActions } from '../../containers/manage-edit-overtime-page/actions/manage-edit-overtime-page.actions';
import { ManageAddAbsencePageActions } from '../../containers/manage-add-absence-page/actions/manage-add-absence-page.actions';
import { PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { EmployeeDetailsApiActions } from '@mentor-one-ui/employees/state/actions/employee-details.actions';
import { BalanceModel } from '@mentor-one-ui/time/my-time/balance/models/balance.model';
import { SickLeaveFollowUpPageActions } from '@mentor-one-ui/sick-leave/follow-up/state/follow-up.actions';

export const featureKey = 'leaveAdministration';

export enum AbsenceRegisterState {
  SelectEmployees = 0,
  SelectAbsenceType = 1,
  extendAbsence = 2,
}

export interface State {
  selectedEmployeeIds: number[];
  employeeNameFilter: string;
  state: AbsenceRegisterState;
  isSaving: boolean;
  selectedAbsenceTypeId: number;
  SelectedOvertimeTypeId: number;
  hasFormChanges?: boolean;
  personnelManagers: PersonnelManagerModel[];
  balanceForSingleEmployee?: BalanceModel[];
}

export const initialState: State = {
  selectedEmployeeIds: [],
  employeeNameFilter: '',
  state: AbsenceRegisterState.SelectEmployees,
  isSaving: false,
  selectedAbsenceTypeId: -1,
  SelectedOvertimeTypeId: -1,
  hasFormChanges: false,
  personnelManagers: [],
  balanceForSingleEmployee: [],
};

export const reducer = createReducer(
  initialState,
  on(ManageAddAbsencePageActions.enter, ManageAddAbsencePageActions.leave, (state) => {
    if (state.state == AbsenceRegisterState.extendAbsence) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      selectedAbsenceTypeId: -1,
      employeeNameFilter: '',
      balanceForSingleEmployee: [],
      selectedEmployeeIds: [],
    };
  }),
  on(ManageAddAbsencePageActions.leave, (state) => {
    return initialState;
  }),
  on(ManageAddAbsencePageActions.SelectAbsenceType, (state, { absenceTypeId }): State => {
    return {
      ...state,
      selectedAbsenceTypeId: absenceTypeId,
    };
  }),
  on(SickLeaveFollowUpPageActions.ExtendAbsence, (state, { absenceTypeId, employeeId }): State => {
    return {
      ...state,
      selectedAbsenceTypeId: absenceTypeId,
      selectedEmployeeIds: [employeeId],
      state: AbsenceRegisterState.extendAbsence,
    };
  }),
  on(ManageAddAbsencePageActions.SelectOvertimeType, (state, { overtimeTypeId }): State => {
    return {
      ...state,
      SelectedOvertimeTypeId: overtimeTypeId,
    };
  }),
  on(
    ManageAddAbsencePageActions.registerNewAbsence,
    ManageAddAbsencePageActions.registerNewOvertime,
    ManageAddAbsencePageActions.ExtendAbsence,
    (state, action) => {
      return {
        ...state,
        isSaving: true,
      };
    }
  ),
  on(ManageApiActions.RegisterBulkLeaveSuccess, ManageApiActions.RegisterBulkLeaveError, (state, action) => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(ManageAddAbsencePageActions.resetSelection, (state, action) => {
    if (state.selectedAbsenceTypeId > -1) {
      return {
        ...state,
        state: AbsenceRegisterState.SelectAbsenceType,
        selectedAbsenceTypeId: -1,
      };
    } else if (state.SelectedOvertimeTypeId > -1) {
      return {
        ...state,
        state: AbsenceRegisterState.SelectAbsenceType,
        SelectedOvertimeTypeId: -1,
      };
    } else
      return {
        ...state,
        state: AbsenceRegisterState.SelectEmployees,
        balanceForSingleEmployee: [],
      };
  }),
  on(ManageAddAbsencePageActions.resetSelection, (state, action) => {
    return {
      ...state,
      employeeNameFilter: '',
    };
  }),
  on(ManageAddAbsencePageActions.enter, (state, action) => {
    if (state.state == 2) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      state: AbsenceRegisterState.SelectEmployees,
      selectedEmployeeIds: [],
      isSaving: false,
      personnelManagers: [],
    };
  }),
  on(ManageAddAbsencePageActions.setCurrentUserAsPersonnelManager, (state, action) => {
    return {
      ...state,
      personnelManagers: [action.personnelManager],
    };
  }),
  on(ManageAddAbsencePageActions.selectEmployees, (state, action) => {
    return {
      ...state,
      selectedEmployeeIds: action.employeeIds,
    };
  }),
  on(ManageAddAbsencePageActions.filterEmployeesByName, (state, action) => {
    return {
      ...state,
      employeeNameFilter: action.query,
    };
  }),
  on(ManageAddAbsencePageActions.startNewLeaveRegistration, (state, action) => {
    return {
      ...state,
      selectedAbsenceTypeId: -1,
      SelectedOvertimeTypeId: -1,
      state: AbsenceRegisterState.SelectAbsenceType,
    };
  }),
  on(ManageEditAbsencePageActions.HasFormChanges, (state, { hasChanges }) => ({
    ...state,
    hasFormChanges: hasChanges,
  })),
  on(ApplicationActions.DiscardUnsavedChanges, (state) => ({
    ...state,
    hasFormChanges: false,
  })),
  on(ManageEditAbsencePageActions.DeleteAbsence, (state) => ({
    ...state,
    isDeleting: true,
  })),
  on(ManageApiActions.DeleteLeaveSuccess, (state) => ({
    ...state,
    isDeleting: false,
  })),
  on(ManageEditAbsencePageActions.EditAbsence, ManageEditOvertimePageActions.EditOvertime, (state) => ({
    ...state,
    isSaving: true,
  })),
  on(ManageApiActions.EditAbsenceSuccess, ManageApiActions.EditOvertimeSuccess, (state) => ({
    ...state,
    isSaving: false,
    hasFormChanges: false,
  })),
  on(ManageApiActions.EditAbsenceError, ManageApiActions.EditOvertimeError, (state) => ({
    ...state,
    isSaving: false,
  })),
  on(EmployeeDetailsApiActions.getEmployeePersonnelManagersSuccess, (state, { personnelManagers }) => ({
    ...state,
    personnelManagers: personnelManagers,
  })),
  on(EmployeeDetailsApiActions.getBalanceForSingleEmployeeSuccess, (state, { balance }) => ({
    ...state,
    balanceForSingleEmployee: balance,
  }))
);
