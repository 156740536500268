import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { Observable, map } from 'rxjs';
import { selectAllemployees, selectEmployeeFirstNameById, selectEmployeeFullNameById } from '../state/selectors/employee.selectors';
import { Store } from '@ngrx/store';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';

@Pipe({
    name: 'employeeFullName',
    pure: false,
    standalone: true,
})
export class EmployeeFullNamePipe implements PipeTransform {
  transform(employee: EmployeeModel): string {
    return `${employee.ContactInformation?.FirstName} ${employee.ContactInformation?.LastName}`;
  }
}

@Pipe({
    name: 'employeeFullNameFromId',
    pure: false,
    standalone: true,
})
export class EmployeeFullNameFromIdPipe implements PipeTransform {
 
  constructor(private store: Store,
    private translationService: TranslationDataService) { }

  transform(employeeId: number): Observable<string> {
    return this.store.select(selectEmployeeFullNameById(employeeId)).pipe(map(({
      name, 
      archived = false, 
      terminationInProcess = false,
      isCorporationUser = false
    }) => {
      if(archived) {
        return name + ` (${this.translationService.translate('archived').toLowerCase()})`
      } else if(terminationInProcess) {
        return name + ` (${this.translationService.translate('inactive').toLowerCase()})`
      } else if(isCorporationUser) {
        return name + ` (${this.translationService.translate('corporation-user').toLowerCase()})`
      }  else {
        return name;
      }
    }));
  }
}

@Pipe({
    name: 'employeeFirstNameFromId',
    pure: false,
    standalone: true,
})
export class EmployeeFirstNameFromIdPipe implements PipeTransform {
 
  constructor(private store: Store,
    private translationService: TranslationDataService) { }

  transform(employeeId: number): Observable<string> {
    return this.store.select(selectEmployeeFirstNameById(employeeId)).pipe(map(({
      name, 
      archived = false, 
      terminationInProcess = false,
      isCorporationUser = false
    }) => {
      if(archived) {
        return name + ` (${this.translationService.translate('archived').toLowerCase()})`
      } else if(terminationInProcess) {
        return name + ` (${this.translationService.translate('inactive').toLowerCase()})`
      } else if(isCorporationUser) {
        return name + ` (${this.translationService.translate('corporation-user').toLowerCase()})`
      } else {
        return name;
      }
    }));
  }
}



@Pipe({
    name: 'employeeWithJobTitle',
    pure: false,
    standalone: true,
})
export class EmployeeWithJobTitlePipe implements PipeTransform {
  employees: EmployeeModel[];

  constructor(private store: Store) {
    this.store.select(selectAllemployees).subscribe((employees) => (this.employees = employees));
  }
  transform(employeeId: number | null): string {
    if (!employeeId) {
      return '';
    }
    var employee = this.employees?.find((f) => f.EmployeeId === employeeId);
    if (employee && !employee.JobTitle) {
      return `${employee.ContactInformation?.FirstName} ${employee.ContactInformation?.LastName}`;
    } else if (employee && employee.JobTitle) {
      return `${employee.ContactInformation?.FirstName} ${employee.ContactInformation?.LastName}, ${employee.JobTitle}`;
    } else {
      return '';
    }
  }
}
