import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { CollapsableInfoPanelComponent } from '../../../../core/components/collapsable-info-panel/collapsable-info-panel.component';
import { InputText } from 'primeng/inputtext';
import { DefaultButtonsComponent } from '../../../../core/components/default-buttons/default-buttons.component';
import { AsyncPipe } from '@angular/common';
import { TranslatePipe } from '../../../../core/pipes/translate.pipe';

@Component({
    selector: 'mo-about-form',
    template: `
    <div>
      <form [formGroup]="form">
        <div class="pbl-grid">
          <legend class="pbl-grid-largest">{{ 'contact-information' | translate | async }}</legend>
          <mo-collapsable-info-panel class="pbl-grid-largest" [collapsed]="true" header="{{'about-edit-info-header' | translate | async}}" infoText="{{'about-edit-info-text' | translate | async}}"></mo-collapsable-info-panel>
          <div class="pbl-grid-medium pbl-input-text">
            <label for="Name">{{ 'name' | translate | async }}</label>
            <input id="Name" type="text" pInputText formControlName="Name" maxlength="250" />
          </div>
          <div class="pbl-grid-medium pbl-input-text">
            <label for="OrganizationNumber">{{ 'organization-nr' | translate | async }}</label>
            <input id="OrganizationNumber" type="text" pInputText formControlName="OrganizationNumber" maxlength="50" />
          </div>
          <div class="pbl-grid-medium pbl-input-text">
            <label for="BusinessNumber">{{ 'business-nr' | translate | async }}</label>
            <input id="BusinessNumber" type="text" pInputText formControlName="BusinessNumber" maxlength="50" />
          </div>
          <div class="pbl-grid pbl-grid-largest">
            <div class="pbl-grid-small pbl-input-text">
              <label for="PhoneNumber">{{ 'phone' | translate | async }}</label>
              <input id="PhoneNumber" type="tel" pInputText formControlName="PhoneNumber" required maxlength="20" />
            </div>
          </div>
          <div class="pbl-grid-medium pbl-input-text">
            <label for="PostalAddress">{{ 'postal-address' | translate | async }}</label>
            <input id="PostalAddress" type="text" pInputText formControlName="PostalAddress" required maxlength="250" />
          </div>
          <div class="pbl-grid-small pbl-input-text">
            <label for="PostalZipCode">{{ 'postal-zip-code' | translate | async }}</label>
            <input id="PostalZipCode" type="text" pInputText formControlName="PostalZipCode" required maxlength="50" />
          </div>
          <div class="pbl-grid-medium pbl-input-text">
            <label for="PostalCity">{{ 'postal-place' | translate | async }}</label>
            <input id="PostalCity" type="text" pInputText formControlName="PostalCity" required maxlength="250" />
          </div>
          <div class="pbl-grid-medium pbl-input-text app-about__visitadress">
            <label for="VisitAddress">{{ 'location-address' | translate | async }}</label>
            <input id="VisitAddress" type="text" pInputText formControlName="VisitAddress" maxlength="250" />
          </div>
          <div class="pbl-grid-small pbl-input-text">
            <label for="VisitZipCode">{{ 'postal-zip-code' | translate | async }}</label>
            <input id="VisitZipCode" type="text" pInputText formControlName="VisitZipCode" maxlength="50" />
          </div>
          <div class="pbl-grid-medium pbl-input-text">
            <label for="VisitCity">{{ 'postal-place' | translate | async }}</label>
            <input id="VisitCity" type="text" pInputText formControlName="VisitCity" maxlength="250" />
          </div>
        </div>
      </form>

      <div class="p-dialog-footer p-dialog-footer--sticky">
        <mo-default-buttons
          [showDelete]="false"
          [canSave]="form.dirty"
          [isSaving]="isSaving"
          (cancel)="Cancel.emit()"
          (save)="SaveForm.emit()"
        ></mo-default-buttons>
      </div>
    </div>
  `,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CollapsableInfoPanelComponent,
        InputText,
        DefaultButtonsComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AboutFormComponent implements OnInit {
  // upload image file
  @Input() form: FormGroup;
  @Input() isSaving: boolean;
  @Output() SaveForm = new EventEmitter<any>();
  @Output() Cancel = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}


}
