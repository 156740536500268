import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { createAction, props } from '@ngrx/store';

const Enter = createAction('[User Organization - About Page] Enter');

const OrganizationMapPageEnter = createAction('[User Organization - Organization Map Page] Enter');
const ContactListPageEnter = createAction('[User Organization - Contact List Page] Enter');

//

const saveUserOrganization = createAction(
  '[Organization - About Page] Save organization details',
  props<{ organization: UserOrganizationModel }>()
);

const loadAboutImage = createAction('[Organization - About | API] Load organization image');
const saveAboutImage = createAction(
  '[Organization - About | API] Save organization image',
  props<{ imageFile: any }>()
);
const setAboutImage = createAction(
  '[Organization - About | API] Set organization image',
  props<{ localImageUrl: string }>()
);
const deleteAboutImage = createAction('[Organization - About | API] Delete organization image');

const loadOrganizationMap = createAction('[Organization - About | API] Load organization map');
const saveOrganizationMap = createAction(
  '[Organization - About | API] Save organization map started',
  props<{ imageFile: any }>()
);
const setOrganizationMap = createAction(
  '[Organization - About | API] Set organization map',
  props<{ localImageUrl: string }>()
);
const deleteOrganizationMap = createAction('[Organization - About | API] Delete organization map');

//

const ShowDetailsModal = createAction('[Organization - About Page] Show Details Modal');
const HideDetailsModal = createAction('[Organization - About Page] Hide Details Modal');
const DetailsModalWasClosed = createAction('[Organization - About Page] Details Modal Was Closed');
const ShowProfileModal = createAction('[Organization - Profile Page] Show Profile Modal');
const HideProfileModal = createAction('[Organization - Profile Page] Hide Profile Modal');
const ProfileModalWasClosed = createAction('[Organization - Profile Page] Profile Modal Was Closed');
const loadOrganizationStats = createAction('[Organization - About | API] Load organization stats');

export const UserOrganizationPageActions = {
  Enter,
  // DepartmentsPageEnter, // has its own action files
  OrganizationMapPageEnter,
  ContactListPageEnter,

  ShowDetailsModal,
  HideDetailsModal,
  DetailsModalWasClosed,

  ShowProfileModal,
  HideProfileModal,
  ProfileModalWasClosed,
  saveUserOrganization,

  setAboutImage, // ?????
  loadAboutImage,
  saveAboutImage,
  deleteAboutImage,

  setOrganizationMap, // ????
  loadOrganizationMap,
  saveOrganizationMap,
  deleteOrganizationMap,

  loadOrganizationStats,
};
