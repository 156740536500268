import { MentorAdminOrganizationFilterModel } from '@mentor-one-ui/core/models/admin-organization/admin-organization.model';
import { createAction, props } from '@ngrx/store';

const Enter = createAction('[Organization List] Enter');

export const SetFilter = createAction('[Organization List] Set filter',
props<{ filter: MentorAdminOrganizationFilterModel}>());

export const ClearFilter = createAction('[Organization List] Clear filter');

export const OrganizationsPageActions = {
  Enter,
  SetFilter,
  ClearFilter
};
