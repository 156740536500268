import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Textarea } from 'primeng/textarea';
import { DefaultButtonsComponent } from '../../../../core/components/default-buttons/default-buttons.component';
import { AsyncPipe } from '@angular/common';
import { TranslatePipe } from '../../../../core/pipes/translate.pipe';

@Component({
    selector: 'mo-profile-form',
    template: `
    <div>
        <form [formGroup]="form">
        <div class="pbl-grid">
          <div class="pbl-grid-largest pbl-input-text">
            <p>
              {{'visionInfoText' | translate | async}}
            </p>
            <textarea pTextarea rows="8" id="Vision" formControlName="Vision" maxlength="4000" ></textarea>
          </div>
        </div>
      </form>

      <div class="p-dialog-footer p-dialog-footer--sticky">
        <mo-default-buttons
          [showDelete]="false"
          [canSave]="true"
          [isSaving]="isSaving"
          (cancel)="Cancel.emit()"
          (save)="SaveForm.emit()"
        ></mo-default-buttons>
      </div>
    </div>
  `,
    styleUrls: ['./profile-form.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, Textarea, DefaultButtonsComponent, AsyncPipe, TranslatePipe]
})
export class ProfileFormComponent {
  @Input() form: FormGroup;

  @Input() isSaving: boolean;
  @Output() SaveForm = new EventEmitter<any>();
  @Output() Cancel = new EventEmitter<any>();

  constructor() {

  }
}
