import { MentorAdminSystemMessageModel } from '@mentor-one-ui/core/models/mentor-admin-system-messages/mentor-admin-system-message.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SystemMessagesActions } from './system-messages.actions';


export const systemMessagesFeatureKey = 'systemMessages';


export const adapter: EntityAdapter<MentorAdminSystemMessageModel> = createEntityAdapter<MentorAdminSystemMessageModel>({
  sortComparer: false,
  selectId: (s) => s.SystemMessageId
});

export interface State extends EntityState<MentorAdminSystemMessageModel> {

}

export const initialState: State = adapter.getInitialState({

});

export const systemMessageReducer = createReducer(
  initialState,
  on(SystemMessagesActions.LoadSystemMessagesSuccess, (state, action) => adapter.setAll(action.list, state)),
  on(SystemMessagesActions.GetSelectedSystemMessageSuccess, (state, action) => adapter.upsertOne(action.message, state)),
  on(SystemMessagesActions.DeleteSystemMessageSuccess, (state, action) => adapter.removeOne(action.id, state)),

);
