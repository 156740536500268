import { Pipe, PipeTransform } from "@angular/core";
import { TranslationDataService } from "../services/translation-data.service";


@Pipe({
    name: 'culturecode',
    pure: false,
    standalone: true
})
export class CultureCodePipe implements PipeTransform {

  constructor(private translationService: TranslationDataService) {
  }

  transform(code: string): string {
    let lang: string = '';

    switch (code) {
      case 'nb-NO':
        return this.translationService.translate('lang-norwegian');
      case 'en-US':
        return this.translationService.translate('lang-english');
      case 'en-GB':
        return this.translationService.translate('lang-english');
      default:
        return lang;
    }
  }
}
