import { Action, ActionReducerMap, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromOrganizations from '@mentor-one-ui/mentor-admin/admin-organizations/state';
import * as fromEmployees from '@mentor-one-ui/mentor-admin/admin-employees/state';
import * as fromSystemMessages from '@mentor-one-ui/mentor-admin/system-messages/state';

export const mentorAdminFeatureKey = 'mentor-admin';

export interface mentorAdminState {
  [fromOrganizations.organizationsFeatureKey]: fromOrganizations.State;
  [fromEmployees.employeesFeatureKey]: fromEmployees.State;
  [fromSystemMessages.systemMessagesFeatureKey]: fromSystemMessages.State;
}

export function reducers(state: mentorAdminState | undefined, action: Action) {
  return combineReducers({
    [fromOrganizations.organizationsFeatureKey]: fromOrganizations.adminOrgReducer,
    [fromEmployees.employeesFeatureKey]: fromEmployees.adminEmployeeReducer,
    [fromSystemMessages.systemMessagesFeatureKey]: fromSystemMessages.systemMessageReducer,
  })(state, action);
}

export const selectMentorAdminState = createFeatureSelector<mentorAdminState>(mentorAdminFeatureKey);
