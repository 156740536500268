import { act } from '@ngrx/effects';
import { definePreset, palette } from '@primeng/themes';
import Aura from '@primeng/themes/aura';

const MentorPlusTheme = definePreset(Aura, {
  primitive: {
    borderRadius: {
      none: '0',
      xs: '2px',
      sm: '4px',
      md: '6px',
      lg: '8px',
      xl: '12px',
    },
  },
  components: {
    panelmenu: {
      gap: '0.5rem',
      panel: {
        padding: '0',
      },
      submenu: {
        indent: '0',
      },
      item: {
        padding: '1rem',
      },
    },
    chip: {
      colorScheme: {
        light: {
          background: 'var(--mentor-blue-10)',
          color: 'var(--mentor-blue-100)',
          remove: {
            icon: {
              color: 'unset',
            },
          },
        },
      },
    },
    autocomplete: {
      colorScheme: {
        light: {
          dropdown: {
            background: 'var(--mentor-primary-base)',
            color: 'var(--mentor-primary-contrast)',
            borderColor: 'var(--mentor-primary-base)',
            focusRingColor: 'var(--mentor-teal-100)',
            hover: {
              background: 'var(--mentor-primary-dark)',
              borderColor: 'var(--mentor-primary-dark)',
              color: 'var(--mentor-primary-contrast)',
            },
            active: {
              background: 'var(--mentor-primary-darker)',
              borderColor: 'var(--mentor-primary-darker)',
              color: 'var(--mentor-primary-contrast)',
            },
          },
        },
      },
    },
    button: {
      colorScheme: {
        light: {
          primary: {
            background: 'var(--mentor-primary-base)',
            color: 'var(--mentor-primary-contrast)',
            borderColor: 'var(--mentor-primary-base)',
            focusRingColor: 'var(--mentor-teal-100)',
          },
          secondary: {
            background: 'var(--mentor-background-base)',
            color: 'var(--mentor-primary-base)',
            borderColor: '#bdbdbd',
            focusRingColor: 'var(--mentor-teal-100)',
            hover: {
              background: 'var(--mentor-teal-10)',
              borderColor: 'var(--mentor-primary-base)',
            },
            active: {
              background: 'var(--mentor-teal-10)',
              borderColor: 'var(--mentor-primary-base)',
            },
          },
          danger: {
            background: 'var(--mentor-background-base)',
            color: 'var(--mentor-rusty-red-100)',
            borderColor: '#bdbdbd',
            focusRingColor: 'var(--mentor-rusty-red-50)',
            hover: {
              background: 'var(--mentor-rusty-red-25)',
              color: 'var(--mentor-rusty-red-100)',
              borderColor: 'var(--mentor-rusty-red-100)',
            },
            active: {
              background: 'var(--mentor-rusty-red-25)',
              color: 'var(--mentor-rusty-red-100)',
              borderColor: 'var(--mentor-rusty-red-100)',
            },
          },
          warn: {
            background: 'var(--mentor-orange-100)',
            color: '#fff',
            borderColor: 'var(--mentor-orange-100)',
            focusRingColor: 'var(--mentor-orange-100)',
            hover: {
              background: 'var(--mentor-orange-75)',
              borderColor: 'var(--mentor-orange-100)',
            },
            active: {
              background: 'var(--mentor-orange-75)',
              borderColor: 'var(--mentor-orange-100)',
            },
          },
        },
      },
    },
    datatable: {
      colorScheme: {
        light: {
          header: {
            background: 'var(--mentor-background-base)',
            color: 'var(--mentor-text-heading)',
            cell: {
              background: 'var(--mentor-background-base)',
              selected: {
                background: 'rgba(173, 199, 204, 0.2)',
              },
            },
          },
        },
      },
    },
    listbox: {
      colorScheme: {
        light: {
          option: {
            group: {
              padding: '1rem 1rem 0.75rem'
            },
            padding: '0.75rem 1rem'
          }
        }
      }
    },
    tag: {
      colorScheme: {
        light: {
          primary: {
            background: 'var(--mentor-primary-base)',
            color: 'var(--mentor-primary-contrast)',
          },
          info: {
            background: 'rgb(50,50,50)',
            color: 'rgb(240,240,240)',
          },
          danger: {
            background: 'var(--mentor-rusty-red-100)',
            color: 'rgb(255,255,255)',
          },
          success: {
            background: '{primary.600}',
            color: '#fff',
          },
        },
      },
    },
    message: {
      contentPadding: '1rem',
      colorScheme: {
        light: {
          info: {
            background: 'var(--mentor-blue-25)',
            color: 'var(--mentor-blue-100)',
          },
          error: {
            background: 'var(--mentor-rusty-red-25)',
            color: 'var(--mentor-rusty-red-100)',
          },
          warn: {
            background: 'var(--mentor-sand-100)',
            color: 'var(--mentor-brown-100)',
          },
        },
      },
    },
    tabs: {
      colorScheme: {
        light: {
          background: 'var(--mentor-background-base)',
          color: 'var(--mentor-primary-base)',
          borderColor: 'var(--mentor-background-base)',
          focusRingColor: 'var(--mentor-teal-100)',
          tablist: {
            background: 'var(--mentor-background-base)',
          },
          tab: {
            background: 'var(--mentor-background-panel)',
            color: 'var(--mentor-text-base)',
            borderColor: 'var(--mentor-background-base)',
            focusRingColor: 'var(--mentor-teal-100)',
            margin: '0 3px 0 0',
            padding: '0.75rem 1.5rem',
            hover: {
              background: 'var(--mentor-background-base)',
              borderColor: 'var(--mentor-blue-25)',
            },
            active: {
              background: 'var(--mentor-active-bg)',
              borderColor: 'var(--mentor-primary-base)',
              color: 'var(--mentor-active-color)',
            },
          },
        },
      },
    },
    toggleswitch: {
      colorScheme: {
        light: {
          disabled: {
            background: 'var(--p-surface-600)',
          },
          handle: {
            disabled: {
              background: 'var(--p-surface-0)',
            },
          },
        },
      },
    },
    stepper: {
      colorScheme: {
        light: {
          step: {
            number: {
              color: 'var(--p-text-color)',
              size: '2rem',
              border: {
                color: 'var(--mentor-blue-25)',
              },
              active: {
                color: 'color: var(--mentor-active-color)',
                background: 'var(--mentor-light-blue-100)',
              },
            },
            title: {
              color: 'var(--p-text-color)',
              font: {
                weight: '100',
              },
              active: {
                color: 'var(--mentor-active-color)',
              },
            },
          },
          separator: {
            background: 'var(--mentor-grey-25)',
            size: '1px',
          },
        },
      },
    },
  },
  semantic: {
    transitionDuration: '0.2s',
    focusRing: {
      width: '2px',
      style: 'solid',
      color: 'var(--mentor-teal-100)',
      offset: '1px',
      shadow: 'none',
    },
    disabledOpacity: '0.7',
    primary: palette('#07575b'),
    formField: {
      paddingX: '1.25rem',
      paddingY: '0.75rem',
      sm: {
        fontSize: '0.875rem',
        paddingX: '1.1rem',
        paddingY: '0.66rem',
      },
      lg: {
        fontSize: '1.125rem',
        paddingX: '1.5rem',
        paddingY: '1rem',
      },
      border: {
        radius: 'var(--mentor-border-radius)',
      },
      focusRing: {
        width: '2px',
        style: 'solid',
        color: 'var(--mentor-teal-100)',
        offset: '1px',
        shadow: 'none',
      },
      transitionDuration: '{transition.duration}',
    },
    list: {
      padding: '0.25rem 0.25rem',
      gap: '2px',
      header: {
        padding: '0.5rem 1rem 0.25rem 1rem',
      },
      option: {
        padding: '.75rem 1.25rem',
        borderRadius: '{border.radius.sm}',
      },
      optionGroup: {
        padding: '.75rem 1.25rem',
        fontWeight: '700',
      },
    },
    content: {
      borderRadius: '{border.radius.md}',
    },
    mask: {
      transitionDuration: '0.15s',
    },
    navigation: {
      list: {
        padding: '0.25rem 0.25rem',
        gap: '2px',
      },
      item: {
        padding: '0.5rem 0.75rem',
        borderRadius: '{border.radius.sm}',
        gap: '0.5rem',
      },
      submenuLabel: {
        padding: '0.5rem 0.75rem',
        fontWeight: '600',
      },
      submenuIcon: {
        size: '0.875rem',
      },
    },
    overlay: {
      select: {
        borderRadius: '{border.radius.md}',
        shadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)',
      },
      popover: {
        borderRadius: '{border.radius.md}',
        padding: '1rem',
        shadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)',
      },
      modal: {
        borderRadius: '{border.radius.xl}',
        padding: '1.25rem',
        shadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)',
      },
      navigation: {
        shadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)',
      },
    },
    colorScheme: {
      light: {
        surface: {
          0: '#ffffff',
          50: '#fafafa',
          100: '#f7f7f7',
          200: '#eee',
          300: '#ddd',
          400: '#bbb',
          500: '#aaa',
          600: '#999',
          700: '#555',
          800: '#444',
          900: '#222',
          950: '#111',
        },
        highlight: {
          background: '{primary.50}',
          focusBackground: '{primary.100}',
          color: '{primary.700}',
          focusColor: '{primary.800}',
        },
        mask: {
          background: 'rgba(0,0,0,0.4)',
          color: '{surface.200}',
        },
        formField: {
          disabledBackground: '{surface.200}',
          filledBackground: '{surface.50}',
          filledHoverBackground: '{surface.50}',
          filledFocusBackground: '{surface.50}',
          borderColor: '{surface.400}',
          hoverBorderColor: '{surface.500}',
          focusBorderColor: 'transparent',
          invalidBorderColor: '{red.400}',
          color: '#222',
          disabledColor: '#666',
          placeholderColor: '#666',
          invalidPlaceholderColor: '{red.600}',
          floatLabelColor: '{surface.500}',
          floatLabelFocusColor: '{primary.600}',
          floatLabelActiveColor: '{surface.500}',
          floatLabelInvalidColor: '{form.field.invalid.placeholder.color}',
          iconColor: '#333',
          shadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05)',
        },
        text: {
          color: '{surface.700}',
          hoverColor: '{surface.800}',
          mutedColor: '{surface.500}',
          hoverMutedColor: '{surface.600}',
        },
        content: {
          background: '{surface.0}',
          hoverBackground: '{surface.100}',
          borderColor: '{surface.200}',
          color: '{text.color}',
          hoverColor: '{text.hover.color}',
        },
        overlay: {
          select: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
          popover: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
          modal: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
        },
        list: {
          option: {
            focusBackground: '{surface.100}',
            selectedBackground: '{highlight.background}',
            selectedFocusBackground: '{highlight.focus.background}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            selectedColor: '{highlight.color}',
            selectedFocusColor: '{highlight.focus.color}',
            icon: {
              color: '{surface.400}',
              focusColor: '{surface.500}',
            },
          },
          optionGroup: {
            background: 'transparent',
            color: '{text.color}',
          },
        },
        navigation: {
          item: {
            focusBackground: '{surface.100}',
            activeBackground: '{surface.100}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            activeColor: '{text.hover.color}',
            icon: {
              color: '{surface.700}',
              focusColor: '{surface.500}',
              activeColor: '{surface.500}',
            },
          },
          submenuLabel: {
            background: 'transparent',
            color: '{text.muted.color}',
          },
          submenuIcon: {
            color: '{surface.600}',
            focusColor: '{surface.700}',
            activeColor: '{surface.700}',
          },
        },
      },
    },
  },
});

export default MentorPlusTheme;
