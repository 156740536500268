import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { selectUserOrganizationState } from '@mentor-one-ui/user-organization/state';
import { createSelector } from '@ngrx/store';

export const selectIsSaving = createSelector(selectUserOrganizationState, (state) => state?.settings?.isSaving);

const selectSettingsPageIsSaving = createSelector(selectUserOrganizationState, (state) => state?.settings?.isSaving);

const selectSettingsPageState = createSelector(selectUserOrganizationState, (state) => state?.settings);

export const selectAbsenceTemplates = createSelector(selectSettingsPageState, (state) =>
  state?.timeManagementTypes.filter((x) => x.TimeManagementType === TimeManagementType.Absence)
);
export const selectOvertimeTemplates = createSelector(selectSettingsPageState, (state) =>
  state?.timeManagementTypes.filter((x) => x.TimeManagementType === TimeManagementType.Overtime)
);

export const selectOvertimeTemplatesTimeForTimeOffIsActive = createSelector(selectSettingsPageState, (state) =>
  state?.timeManagementTypes.filter((x) => x.TimeManagementType === TimeManagementType.Overtime).some((x) => x.Code == 'TIME-FOR-TIME-OFF' && x.IsActive)
);

export const selectLoadError = createSelector(selectSettingsPageState, (state) => state?.loadError);

export const OrganizationSettingsSelectors = {
  selectSettingsPageIsSaving,
};
export const selectEmployeeImportResult = createSelector(selectSettingsPageState, (state) => state?.employeeImportResult);
