import { EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { MentorAdminEmployeeListModel } from '@mentor-one-ui/core/models/employee/MentorAdminEmployeeListModel';
import { createAction, props } from '@ngrx/store';

const GotoOrganizationFailed = createAction('[AdminEmployees/API] GotoOrganizationFailed', props<{ error: string }>());
const SearchSuccess = createAction(
  '[AdminEmployees/API] SearchSuccess',
  props<{ employees: MentorAdminEmployeeListModel[] }>()
);

const EmployeeListSuccess = createAction(
  '[AdminEmployees/API] EmployeeListSuccess',
  props<{ employees: MentorAdminEmployeeListModel[] }>()
);

const ResetTwoFactorSuccess = createAction('[AdminEmployees/API] ResetTwoFactorSuccess');

const EmployeeListNavigateToOrganization = createAction('[AdminEmployees/API] EmployeeList Navigate To Organization', props<{ organizationId: number }>());

const SearchFailed = createAction('[AdminEmployees/API] SearchFailed', props<{ error: string }>());
const EmployeeListFailed = createAction('[AdminEmployees/API] EmployeeListFailed', props<{ error: string }>());

export const AdminEmployeesApiActions = {
  GotoOrganizationFailed,
  SearchSuccess,
  SearchFailed,
  EmployeeListSuccess,
  EmployeeListFailed,
  EmployeeListNavigateToOrganization,
  ResetTwoFactorSuccess
};
