import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbsenceType } from '../my-time/absence/models/absence-type.model';
import { selectAllAbsenceTypes, selectAllOvertimeTypes } from '../state/time-common/selectors/time-common.selectors';
import { OvertimeType } from '../my-time/overtime/models/overtime-type.model';

@Pipe({
    name: 'absenceType',
    pure: false,
    standalone: true,
})
export class AbsenceTypePipe implements PipeTransform {
  absenceTypes: AbsenceType[];

  constructor(private store: Store) {
    this.store.select(selectAllAbsenceTypes).subscribe((absenceTypes) => (this.absenceTypes = absenceTypes));
  }

  transform(abscenceTypeId: number): string {
    var absenceType = this.absenceTypes?.find((f) => f.OrganizationAbsenceMapId === abscenceTypeId);
    if (!absenceType) return '';
    return `${absenceType?.EmojiCode} ${absenceType?.Title}`;
  }
}

@Pipe({
    name: 'absenceTypeName',
    pure: false,
    standalone: true,
})
export class AbsenceTypeNamePipe implements PipeTransform {
  absenceTypes: AbsenceType[];

  constructor(private store: Store) {
    this.store.select(selectAllAbsenceTypes).subscribe((absenceTypes) => (this.absenceTypes = absenceTypes));
  }

  transform(abscenceTypeId: number): string {
    var absenceType = this.absenceTypes?.find((f) => f.OrganizationAbsenceMapId === abscenceTypeId);
    if (!absenceType) return '';
    return `${absenceType?.Title}`;
  }
}
