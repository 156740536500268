import { MentorAdminCreateInternalUser, MentorAdminOrganizationModel } from '@mentor-one-ui/core/models/admin-organization/admin-organization.model';
import { createAction, props } from '@ngrx/store';

const Enter = createAction('[Organization Detail] Enter');

const Save = createAction('[Organization Detail] Save', props<{ organization: MentorAdminOrganizationModel }>());

const CancelEditOrBack = createAction('[Organization Detail] Cancel edit', props<{ organizationId: number }>());

const ShowSupportLoginModal = createAction('[OrganizationDetailsApi] Show Support Login Modal');
const CloseSupportLoginModal = createAction('[OrganizationDetailsApi] Close Support Login Modal');

const OpenAddInternalAdministratorModal = createAction('[OrganizationDetailsApi] Open Add Internal Administrator modal');
const CloseInternalAdministratorModal = createAction('[OrganizationDetailsApi] Close Internal Administrator Modal');

export const OrganizationDetailPageActions = {
  Enter,
  Save,
  CancelEditOrBack,
  ShowSupportLoginModal,
  CloseSupportLoginModal,
  OpenAddInternalAdministratorModal,
  CloseInternalAdministratorModal
};
