import { Injectable } from "@angular/core";
import { UserService } from "@mentor-one-ui/core/services/api/user.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { mergeMap, map, catchError, of, switchMap, filter } from "rxjs";
import { MessageService } from "primeng/api";
import { SetPasswordActions } from "./set-password.action";
import { TranslationDataService } from "@mentor-one-ui/core/services/translation-data.service";
import { selectSelectedToken } from "@mentor-one-ui/set-password/state/set-password.selector";
import { Router } from "@angular/router";

@Injectable()
export class SetPasswordEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private userService: UserService,
    private translationService: TranslationDataService,
    private messageService: MessageService) { }

  sendPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetPasswordActions.SetPasswordPostPassword),
      mergeMap((action) => {
        return this.userService.SetPassword(action.token, action.password).pipe(
          map((data: any) => {
            return SetPasswordActions.SetPasswordPostPasswordSuccess();
          }),
          catchError((error) => {
            return of(SetPasswordActions.SetPasswordPostPasswordFailure({ error: error.message }))
          }
          ));
      })
    );
  });

  checkIfTokenIsCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetPasswordActions.CheckIfTokenIsCompleted),
      concatLatestFrom(() => [this.store.select(selectSelectedToken)]),
      filter(([, token]) => !!token),
      switchMap(([, token]) => {
        return this.userService.CheckIfTokenIsCompleted(token!).pipe(
          map((data: boolean) => {
            return SetPasswordActions.CheckIfTokenIsCompletedSuccess({ status: data});
          }),
          catchError((error) => {
            return of(SetPasswordActions.CheckIfTokenIsCompletedFailure({ error: error.message }))
          }
          ));
      })
    );
  });

  redirectIfTokenIsCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetPasswordActions.CheckIfTokenIsCompletedSuccess),
      map((status) => {
        if (status.status) {
          this.router.navigate(['/']);
        }
      })
    );
  },
  { dispatch: false });

}
