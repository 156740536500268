import { Injectable } from '@angular/core';
import { UserActions } from '@mentor-one-ui/core/state/user/user.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, of, switchMap } from 'rxjs';
import { UserOrganizationsAboutApiActions } from '../components/about/state/actions/about-api.action';
import { OrganizationService } from '@mentor-one-ui/core/services/api/organization.service';
import { OrganizationsApiActions } from '@mentor-one-ui/mentor-admin/admin-organizations/state/actions/organization-list-api.actions';
import { AdminBalancePageActions } from '@mentor-one-ui/time/admin-balance/actions/admin-balance-page.actions';
import { AbsenceSettingsPageActions } from '../components/settings/absence-settings/absence-settings-page/actions/absence-settings-page.actions';
import { TimeManagementTemplate } from '../components/settings/models/time-management-template.model';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { NewEmployeePageActions } from '@mentor-one-ui/employees/new-employee/state/actions/new-employee-page-actions';
import { EmployeeEmploymentTabActions } from '@mentor-one-ui/employees/state/actions/employee-employment.actions';
import { UserOrganizationPageActions } from '../components/about/state/actions/about-page.action';
import { SettingsPageActions, SettingsApiActions } from '../components/settings/state/settings.actions';
import {
  DepartmentsTabActions,
  DepartmentsApiActions,
} from '../components/departments/state/actions/departments.actions';
import { DepartmentService } from '@mentor-one-ui/core/services/api/department.service';
import { FrontpageActions } from '@mentor-one-ui/core/state/frontpage/frontpage.actions';
import { EmployeeBalanceTabActions } from '@mentor-one-ui/employees/state/actions/employee-balance.actions';

@Injectable()
export class UserOrganizationEffects {
  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService,
    private translationService: TranslationDataService,
    private departmentService: DepartmentService
  ) {}

  loadOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.bootstrapApplicationData),
      mergeMap(() =>
        this.organizationService.GetOrganization().pipe(
          map((data: any) => {
            return UserOrganizationsAboutApiActions.loadUserOrganizationSuccess({ organization: data });
          }),
          catchError((err) => {
            return of(UserOrganizationsAboutApiActions.loadUserOrganizationFailure({ error: err.error }));
          })
        )
      )
    );
  });

  loadOrganizationAboutImage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FrontpageActions.Enter,
        UserOrganizationPageActions.loadAboutImage,
        UserOrganizationsAboutApiActions.saveAboutImageSuccess
      ),
      mergeMap(() =>
        this.organizationService.GetOrganizationImage().pipe(
          map((data: any) => {
            return UserOrganizationPageActions.setAboutImage({ localImageUrl: data });
          }),
          catchError((err) => {
            return of(UserOrganizationsAboutApiActions.loadOrganizationImageFailed({ error: err.error }));
          })
        )
      )
    );
  });

  // Load Departments for User Organization

  loadDepartmentsForOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DepartmentsTabActions.loadDepartmentsForOrganization, UserActions.bootstrapApplicationData),
      mergeMap(() =>
        this.departmentService.getDepartments().pipe(
          map((data: any) => {
            return DepartmentsApiActions.loadDepartmentsForOrganizationSuccess({ departments: data });
          }),
          catchError((err) => of(DepartmentsApiActions.loadDepartmentsForOrganizationFailure({ error: err.message })))
        )
      )
    );
  });

  saveOrganizationAboutForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserOrganizationPageActions.saveUserOrganization, SettingsPageActions.saveSettings),
      mergeMap((action) => {
        return this.organizationService.UpdateOrganization(action.organization).pipe(
          map((res) => {
            return SettingsApiActions.saveSettingsSuccess({ organization: res });
          }),
          catchError((err) => {
            return of(
              SettingsApiActions.saveSettingsFailure({
                error: this.translationService.translate('organization-save-error'),
              })
            );
          })
        );
      })
    );
  });

  loadPersonnelManagers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserActions.bootstrapApplicationData,
        EmployeeEmploymentTabActions.OpenEmploymentModal,
        NewEmployeePageActions.Enter
      ),
      mergeMap((action) =>
        this.organizationService.getPersonnelManagers().pipe(
          map((result) => {
            return UserActions.LoadPersonnelManagersSuccess({ managers: result });
          }),
          catchError((err) => of(UserActions.LoadPersonnelManagersError(err.message)))
        )
      )
    );
  });

  loadTimeManagementTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsenceSettingsPageActions.Enter, AdminBalancePageActions.Enter, EmployeeBalanceTabActions.Enter),
      switchMap((action) => {
        return this.organizationService.getTimeManagementTypes().pipe(
          map((result: TimeManagementTemplate[]) => {
            return OrganizationsApiActions.loadTimeManagementTypesSuccess({
              timeManagementTypes: result,
            });
          }),
          catchError((e) =>
            of(
              OrganizationsApiActions.loadTimeManagementTypesError({
                error: this.translationService.translate('could-not-load-absence-types'),
              })
            )
          )
        );
      })
    )
  );
}
