import { createReducer, on } from '@ngrx/store';
import { AggregationTypeEnum, SickLeaveReportFilterModel, SickLeaveReportPartModel } from '../models/sick-leave-reports.model';
import { SickLeaveReportApiActions, SickLeaveReportPageActions } from './sick-leave-report.actions';
import { CareDaysChildrenReportAggregationModel, CareDaysReportFilterModel } from '../models/sick-leave-report-children.model';

export const featureKey = 'reports';

export interface State {
  isLoading: boolean;
  error: boolean;
  filter: SickLeaveReportFilterModel;
  parts: SickLeaveReportPartModel[];
  careDaysParts: CareDaysChildrenReportAggregationModel[];
  careDaysFilter: CareDaysReportFilterModel;
  careDaysIsLoading: boolean;
  careDaysError: boolean;
}

export const initialState: State = {
  isLoading: false,
  error: false,
  filter: {
    Year: new Date().getFullYear(),
    AggregationType: AggregationTypeEnum.ByYear,
    AbsenceReportLengths: [0]
  },
  parts: [],
  careDaysParts: [],
  careDaysFilter: {
    Year: new Date().getFullYear(),
    AggregationType: AggregationTypeEnum.ByYear,
  },
  careDaysIsLoading: false,
  careDaysError: false
};



export const reducer = createReducer<State>(
  initialState,
  on(SickLeaveReportPageActions.FilterChanged, (state, { filter }): State => {
    return {
      ...state,
      filter: filter,
      isLoading: true
    };
  }),
  on(SickLeaveReportApiActions.loadSickLeaveReportsSuccess, (state, { report }): State => {
    return {
      ...state,
      parts: report.Parts,
      isLoading: false
    };
  }),
  on(SickLeaveReportApiActions.loadSickLeaveReportsError, (state): State => {
    return {
      ...state,
      error: true,
      isLoading: false
    };
  }),
  on(SickLeaveReportPageActions.FilterCareDaysChanged, (state, { filter }): State => {
    return {
      ...state,
      careDaysFilter: filter,
      careDaysIsLoading: true
    };
  }),
  on(SickLeaveReportApiActions.loadCareDaysReportsChildrenSuccess, (state, { report }): State => {
    return {
      ...state,
      careDaysParts: report,
      careDaysIsLoading: false
    };
  }),
  on(SickLeaveReportApiActions.loadCareDaysReportsChildrenError, (state): State => {
    return {
      ...state,
      careDaysError: true,
      careDaysIsLoading: false
    };
  }),
);
