import { Routes } from '@angular/router';
import { MentorAdminGuard } from './mentor-admin/mentor-admin.guard';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { TranslationTitleResolver } from './core/resolvers/translation-title.resolver';
import { UnauthorizedComponent } from '@mentor-one-ui/auth/unauthorized/unauthorized.component';
import { SigninOidcComponent } from './auth/signin-oidc/signin-oidc.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { HealthzComponent } from '@mentor-one-ui/healthz/healthz.component';
import { ForbiddenComponent } from './auth/forbidden/forbidden.component';
import { NoAccessComponent } from '@mentor-one-ui/auth/no-access/no-access.component';
import { provideEffects } from '@ngrx/effects';
import { AbsenceEffects } from './time/my-time/state/effects/absence.effects';
import { OvertimeEffects } from './time/my-time/state/effects/overtime.effects';
import { BalanceEffects } from './time/my-time/state/effects/balance.effects';
import { LeaveManagementsEffects } from './time/leave-management/state/effects/leave-management.effects';
import { EmployeesEffects } from './employees/state/effects/employees.effect';
import { EmployeePictureEffects } from './employees/state/effects/employee-picture.effects';
import { OrganizationDetailsEffects } from './user-organization/components/about/state/organization-details.effects';
import { SetPasswordEffects } from './set-password/state/set-password.effects';

export const appRoutes: Routes = [
  {
    path: '',
    // component: FrontpageModule,
    loadChildren: () => import('./frontpage/frontpage-routing').then((m) => m.FrontpageRoutes),
    canActivate: [AutoLoginPartialRoutesGuard],
    providers: [provideEffects(AbsenceEffects, OvertimeEffects)],
  },
  {
    path: 'mentor-admin',
    loadChildren: () => import('./mentor-admin/mentor-admin-routing').then((m) => m.MentorAdminRoutes),
    canActivate: [AutoLoginPartialRoutesGuard, MentorAdminGuard],
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile-page/my-profile-routing').then((m) => m.MyProfileRoutes),
    canActivate: [AutoLoginPartialRoutesGuard],
    providers: [provideEffects(EmployeesEffects, EmployeePictureEffects)],
  },
  {
    path: 'organization',
    loadChildren: () => import('./user-organization/user-organization-routing').then((m) => m.UserOrganizationRoutes),
    data: { activeMenuModule: 'organization', translateTitle: 'the-organization' },
    canActivate: [AutoLoginPartialRoutesGuard],
    title: TranslationTitleResolver,
    providers: [provideEffects(OrganizationDetailsEffects)],
  },
  {
    path: 'employees',
    loadChildren: () => import('./employees/employees-routing').then((m) => m.EmployeeRoutes),
    canActivate: [AutoLoginPartialRoutesGuard],
    title: TranslationTitleResolver,
    data: {
      translateTitle: 'employees',
      activeMenuModule: 'employees',
    },
  },
  {
    path: 'time',
    loadChildren: () => import('./time/time-routing').then((m) => m.TimeRoutes),
    data: {
      activeMenuModule: 'time',
      translateTitle: 'time',
    },
    title: TranslationTitleResolver,
    canActivate: [AutoLoginPartialRoutesGuard],
    providers: [provideEffects(AbsenceEffects, OvertimeEffects, LeaveManagementsEffects, BalanceEffects)],
  },
  {
    path: 'sick-leave',
    loadChildren: () => import('./sick-leave/sick-leave-routing').then((m) => m.SickLeaveRoutes),
    data: {
      activeMenuModule: 'sick-leave',
      translateTitle: 'sick-leave',
    },
    title: TranslationTitleResolver,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'set-password',
    loadChildren: () => import('./set-password/set-password-routing').then((m) => m.SetPasswordRoutes),
    providers: [provideEffects(SetPasswordEffects)],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'no-access', component: NoAccessComponent },
  { path: 'signin-oidc', component: SigninOidcComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'healthz', component: HealthzComponent },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];
