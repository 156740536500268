import { createReducer, on } from '@ngrx/store';
import { OrganizationsApiActions } from '@mentor-one-ui/mentor-admin/admin-organizations/state/actions/organization-list-api.actions';

import { OrganizationDetailsApiActions } from './actions/organization-details-api.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  MentorAdminOrganizationFilterModel,
  MentorAdminOrganizationFilterOptionsModel,
  MentorAdminOrganizationModel,
} from '@mentor-one-ui/core/models/admin-organization/admin-organization.model';
import { AccessControlSystemEditModel } from '@mentor-one-ui/core/models/module-access';
import { OrganizationsPageActions } from './actions/organization-list-page.actions';
import { OrganizationDetailPageActions } from './actions/organization-details-page.actions';

export const organizationsFeatureKey = 'organizations';

/**
 * @ngrx/entity provides a predefined interface for handling
 * a structured dictionary of records. This interface
 * includes an array of ids, and a dictionary of the provided
 * model type by id. This interface is extended to include
 * any additional interface properties.
 */
export interface State extends EntityState<MentorAdminOrganizationModel> {
  moduleaccessdefaults: AccessControlSystemEditModel[];
  filter: MentorAdminOrganizationFilterModel;
  filterOptions: MentorAdminOrganizationFilterOptionsModel;
  isSaving: boolean;
}

/**
 * createEntityAdapter creates an object of many helper
 * functions for single or multiple operations
 * against the dictionary of records. The configuration
 * object takes a record id selector function and
 * a sortComparer option which is set to a compare
 * function if the records are to be sorted.
 */
export const adapter: EntityAdapter<MentorAdminOrganizationModel> = createEntityAdapter<MentorAdminOrganizationModel>({
  sortComparer: false,
  selectId: (s) => s.Id,
});

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: State = adapter.getInitialState({
  moduleaccessdefaults: [],
  filterOptions: new MentorAdminOrganizationFilterOptionsModel(),
  filter: new MentorAdminOrganizationFilterModel(),
  isSaving: false,
});

export const adminOrgReducer = createReducer(
  initialState,
  /**
   * The addMany function provided by the created adapter
   * adds many records to the entity dictionary
   * and returns a new state including those records. If
   * the collection is to be sorted, the adapter will
   * sort each record upon entry into the sorted array.
   */
  on(OrganizationsApiActions.LoadOrganizationsSuccess, (state, { organizations }) => {
    return adapter.addMany(organizations, state);
  }),
  on(OrganizationDetailsApiActions.LoadOrganizationSuccess, (state, { organization }) =>
    adapter.upsertOne(organization, state)
  ),
  on(OrganizationDetailPageActions.Save, (state) => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(OrganizationDetailsApiActions.SaveOrganizationError, (state) => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(OrganizationDetailsApiActions.SaveOrganizationSuccess, (state, { organization }) => {
    return { ...adapter.upsertOne(organization, state), isSaving: false };
  }),
  on(OrganizationDetailsApiActions.DeleteOrganizationSuccess, (state, { organizationId }) => {
    return adapter.removeOne(organizationId, state);
  }),
  on(OrganizationDetailsApiActions.LoadModuleAccessDefaultsSuccess, (state, { moduleaccessdefaults }) => {
    return {
      ...state,
      moduleaccessdefaults,
    };
  }),
  on(OrganizationsPageActions.ClearFilter, (state) => {
    return {
      ...state,
      filter: new MentorAdminOrganizationFilterModel(),
    };
  }),
  on(OrganizationsPageActions.SetFilter, (state, { filter }) => {
    return {
      ...state,
      filter: filter,
    };
  })
);
