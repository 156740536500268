import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { BalancePageActions } from '../../balance/actions/balance-page.actions';
import { BalanceService } from '../../balance/services/balance.service';
import { UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';
import { BalanceModel } from '../../balance/models/balance.model';
import { BalanceApiActions } from '../../balance/actions/balance-api.actions';
import { AdminBalanceApiActions } from '@mentor-one-ui/time/admin-balance/actions/admin-balance-api.actions';
import { AbsencePageActions } from '../../absence/actions/absence-page.actions';

@Injectable()
export class BalanceEffects {
  constructor(private balanceService: BalanceService, private store: Store, private actions$: Actions) {}

  loadBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BalancePageActions.Enter,
        BalancePageActions.Load,
        AbsencePageActions.SelectAbsenceType
      ),
      concatLatestFrom(() => [this.store.select(UserSelectors.selectSelectedUserId)]),
      filter(([action, userId]) => !!userId),
      switchMap(([action, userId]) => {
        return this.balanceService.getBalance(userId!).pipe(
          map((result: BalanceModel[]) => {
            return BalanceApiActions.loadBalanceSuccess({
              balance: result,
            });
          }),
          catchError((e) => of(BalanceApiActions.loadBalanceError(e.message)))
        );
      })
    )
  );
}
