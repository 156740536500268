import { HttpClient } from "@angular/common/http";
import { Injectable, model } from "@angular/core";
import { Observable, map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { BalanceTypeEnum, AdminBalanceModel, UpdateEmployeeBalanceRequestModel, OvertimeAllocationModel, AddEmployeeOvertimeAllocationRequestModel } from "../models/admin-balance.model";


@Injectable({
  providedIn: 'root',
})
export class AdminBalanceService {

  constructor(public http: HttpClient) {
  }

  getBalancesForEmployee(employeeId: number, year: number): Observable<AdminBalanceModel[]> {
    return this.http.get<AdminBalanceModel[]>(environment.API_URL + `/balance/${employeeId}/${year}/get`);
  }

  getOvertimeAllocationsForEmployee(employeeId: number): Observable<OvertimeAllocationModel[]> {
    return this.http.get<OvertimeAllocationModel[]>(environment.API_URL + `/Balance/${employeeId}/allocations/overtime/list`);
  }

  addOvertimeAllocationsForEmployee(employeeId: number, minutes: number, comment: string): Observable<OvertimeAllocationModel> {
    let model: AddEmployeeOvertimeAllocationRequestModel = { Minutes: minutes, Comment: comment };
    return this.http.post<OvertimeAllocationModel>(environment.API_URL + `/Balance/${employeeId}/allocations/overtime/add`, model);
  }

  deleteOvertimeAllocationsForEmployee(employeeId: number, id: number): Observable<OvertimeAllocationModel> {
    return this.http.delete<OvertimeAllocationModel>(environment.API_URL + `/Balance/${employeeId}/allocations/overtime/${id}/delete`);
  }

  updateEmployeeBalanceAllocation(employeeId: number, updatemodel: UpdateEmployeeBalanceRequestModel): Observable<AdminBalanceModel[]> {
    return this.http.post<AdminBalanceModel[]>(environment.API_URL + `/balance/${employeeId}/allocations/update`, updatemodel);
  }

  loadAdminBalance(year: number, absenceId: number | undefined, overtimeId: number | undefined): Observable<AdminBalanceModel[]> {
    const body = {
      Year: year,
      OrganizationAbsenceMapId: absenceId ?? null,
      OrganizationOvertimeMapId: overtimeId ?? null
    };

    return this.http.post<AdminBalanceModel[]>(environment.API_URL + `/balance/list`, body)
      .pipe(map((result: AdminBalanceModel[]) => {
        return result.map((balance: AdminBalanceModel) => {
          balance.Year = year;
          return balance;
        });
      }));
  }
}


