import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonAction, ButtonBarButton } from '@mentor-one-ui/core/models/ButtonBar';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { ButtonBarComponent } from '../button-bar/button-bar.component';

@Component({
    selector: 'mo-default-buttons',
    template: ` <mo-button-bar
    [buttonList]="buttons"
    [isSaving]="isSaving"
    [canSave]="canSave"
    (buttonBarClick)="handleButtonClick($event)"
  >
  </mo-button-bar>`,
    standalone: true,
    imports: [ButtonBarComponent],
})
export class DefaultButtonsComponent implements OnInit, OnChanges {
  @Input() showDelete: boolean;
  @Input() showCancel: boolean = true;
  @Input() showSave: boolean = true;
  @Input() isSaving: boolean;
  @Input() canSave: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() saveLabel: string = 'save';
  @Input() saveIcon: string = 'fal fa-save';
  @Input() cancelLabel: string = 'close';
  @Output() delete: EventEmitter<ButtonBarButton> = new EventEmitter<ButtonBarButton>();
  @Output() save: EventEmitter<ButtonBarButton> = new EventEmitter<ButtonBarButton>();
  @Output() cancel: EventEmitter<ButtonBarButton> = new EventEmitter<ButtonBarButton>();
  buttons: ButtonBarButton[];
  constructor(private translateService: TranslationDataService) {}

  ngOnInit(): void {
    this.defaultButtons();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSaving) {
      this.buttons = this.buttons?.map((button) => {
        if (button.isSaveButton) {
          button.labelTerm = changes.isSaving.currentValue
            ? 'saving'
            : 'save';
        }
        return button;
      });
    }

    if (changes.canSave) {
      this.buttons = this.buttons?.map((button) => {
        if (button.isCancelButton) {
          button.labelTerm = changes.canSave.currentValue
            ? 'cancel'
            : 'close';
        }
        return button;
      });
    }
    if (changes.showCancel) {
      this.showCancel = changes.showCancel.currentValue;
      this.defaultButtons();
    }

    if (changes.showDelete) {
      this.showDelete = changes.showDelete.currentValue;
      this.defaultButtons();
    }
    if (changes.showSave) {
      this.showSave = changes.showSave.currentValue;
      this.defaultButtons();
    }
    if (changes.saveLabel) {
      this.saveLabel = changes.saveLabel.currentValue;
      this.defaultButtons();
    }
  }

  defaultButtons() {
    this.buttons = [
      {
        labelTerm: this.saveLabel,
        visible: this.showSave,
        disabled: false,
        action: ButtonAction.Save,
        icon: this.saveIcon,
        isSaveButton: true,
        isCancelButton: false,
      },
      {
        labelTerm: 'delete',
        visible: this.showDelete,
        disabled: !this.canDelete,
        action: ButtonAction.Delete,
        class: 'p-button-danger',
        icon: 'fal fa-trash-alt',
        isSaveButton: false,
        isCancelButton: false,
      },
      {
        labelTerm: this.cancelLabel,
        visible: this.showCancel,
        disabled: false,
        action: ButtonAction.Cancel,
        class: 'p-button-secondary',
        icon: 'fal fa-times',
        isSaveButton: false,
        isCancelButton: true,
      },
    ];
  }
  handleButtonClick(button: ButtonBarButton) {
    if (button.action === ButtonAction.Delete) {
      this.delete.emit();
    }
    if (button.action === ButtonAction.Cancel) {
      this.cancel.emit();
    }
    if (button.action === ButtonAction.Save) {
      this.save.emit();
    }
  }
}
