import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';
import { NoAccessComponent } from './no-access/no-access.component';



@NgModule({
    exports: [AuthModule],
    imports: [AuthModule.forRoot({
        config: {
            postLoginRoute: '/',
            forbiddenRoute: '/forbidden',
            unauthorizedRoute: '/unauthorized',
            logLevel: LogLevel.Warn,
            historyCleanupOff: false,
            authority: environment.Identity_URL,
            redirectUrl: `${window.location.origin}/signin-oidc`,
            postLogoutRedirectUri: window.location.origin,
            clientId: 'pluss',
            scope: 'openid profile offline_access core-api',
            responseType: 'code',
            silentRenew: true,
            useRefreshToken: true,
            secureRoutes: [environment.API_URL]
        },
    }), UnauthorizedComponent,
    SigninOidcComponent,
    NoAccessComponent]
})
export class AuthConfigModule { }
