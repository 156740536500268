import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';
import { selectRoutedAbsenceId } from '@mentor-one-ui/core/state/router/router.selectors';
import { UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { switchMap, map, catchError, of, exhaustMap, Subject, filter } from 'rxjs';
import { AbsenceService } from '../../absence/absence.service';
import { AbsenceApiActions } from '../../absence/actions/absence-api.actions';
import {
  AbsencePageActions,
  EditAbsencePageActions,
  NewAbsencePageActions,
} from '../../absence/actions/absence-page.actions';
import { AbsenceModel } from '../../absence/models/absence.model';
import {
  selectSelectedAbsenceType,
  selectHasAbsenceFormChanges,
  selectPageSize,
  selectPage,
  selectFilterByAbsenceType,
  selectFilterByStatus,
} from '../../absence/state/absence.selectors';
import { AbsenceEmployeeListResponseModel } from '../../overtime/models/absence-employee-list-response.model';
import { FrontpageActions } from '@mentor-one-ui/core/state/frontpage/frontpage.actions';
import { selectSelectedEmployeeIds } from '@mentor-one-ui/time/leave-management/state/leave-administration.selectors';
import { ManageAddAbsencePageActions } from '@mentor-one-ui/time/leave-management/containers/manage-add-absence-page/actions/manage-add-absence-page.actions';
import { selectSelectedFollowUpId } from '@mentor-one-ui/sick-leave/follow-up/state/selectors';

@Injectable()
export class AbsenceEffects {
  constructor(
    private absenceService: AbsenceService,
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private translationService: TranslationDataService,
    private confirmationService: ConfirmationService
  ) {}

  loadAbsences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FrontpageActions.LoadProcessedTimeBox,
        AbsencePageActions.Enter,
        AbsencePageActions.filterByAbsenceType,
        AbsencePageActions.filterByStatus,
        AbsencePageActions.resetFilter,
        AbsencePageActions.changePage
      ),
      concatLatestFrom(() => [
        this.store.select(UserSelectors.selectSelectedUserId),
        this.store.select(selectPageSize),
        this.store.select(selectPage),
        this.store.select(selectFilterByAbsenceType),
        this.store.select(selectFilterByStatus),
      ]),
      filter(([action, userId]) => userId !== null && userId > 0),
      switchMap(([action, userId, pageSize, page, filterByAbsenceTypes, filterByStatus]) => {
        const skip = page * pageSize;
        const take = pageSize;
        return this.absenceService.getAbsences(userId!, filterByAbsenceTypes, filterByStatus, skip, take).pipe(
          map((result: AbsenceEmployeeListResponseModel) => {
            return AbsenceApiActions.loadAbsencesSuccess({
              absences: result.Items,
              totalItems: result.Count,
            });
          }),
          catchError((e) => of(AbsenceApiActions.loadAbsencesError(e.message)))
        );
      })
    )
  );

  registerAbsence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AbsencePageActions.Register),
      concatLatestFrom(() => this.store.select(UserSelectors.selectSelectedUserId)),
      switchMap(([action, userId]) =>
        this.absenceService.registerAbsence(userId!, action.absence).pipe(
          map((absence: AbsenceModel) => {
            return AbsenceApiActions.registerAbsenceSuccess({
              absence: absence,
            });
          }),
          catchError((e) => of(AbsenceApiActions.registerAbsenceError(e.message)))
        )
      )
    );
  });

  registerAbsenceSuccessToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AbsenceApiActions.registerAbsenceSuccess),
      map((action) => {
        return ApplicationActions.SuccessMessage({
          title: this.translationService.translate('absence-registered'),
          message: '',
        });
      })
    );
  });

  editAbsenceSuccessToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AbsenceApiActions.UpdateAbsenceSuccess),
      map((action) => {
        return ApplicationActions.SuccessMessage({
          title: this.translationService.translate('absence-updated'),
          message: '',
        });
      })
    );
  });

  deleteAbsenceSuccessToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AbsenceApiActions.DeleteAbsenceSuccess),
      map((action) => {
        return ApplicationActions.SuccessMessage({
          title: this.translationService.translate('absence-deleted'),
          message: '',
        });
      })
    );
  });

  registerAbsenceErrorToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AbsenceApiActions.registerAbsenceError),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('could-not-register-absence'),
          message: action.message,
        });
      })
    );
  });

  editAbsenceErrorToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AbsenceApiActions.UpdateAbsenceError),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('could-not-update-registration'),
          message: action.message,
        });
      })
    );
  });

  deleteAbsenceErrorToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AbsenceApiActions.DeleteAbsenceError),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('could-not-delete-absence'),
          message: action.message,
        });
      })
    );
  });

  editAbsence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditAbsencePageActions.EditAbsence),
      switchMap((action) =>
        this.absenceService.updateAbsence(action.absence).pipe(
          map((absence: AbsenceModel) => {
            return AbsenceApiActions.UpdateAbsenceSuccess({
              absence: absence,
            });
          }),
          catchError((e) => of(AbsenceApiActions.UpdateAbsenceError(e.message)))
        )
      )
    );
  });

  deleteAbsence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditAbsencePageActions.DeleteAbsenceConfirmed),
      concatLatestFrom(() => this.store.select(selectRoutedAbsenceId)),
      switchMap(([action, absenceId]) =>
        this.absenceService.deleteAbsence(+absenceId!).pipe(
          map(() => {
            return AbsenceApiActions.DeleteAbsenceSuccess({
              absenceId: +absenceId!,
            });
          }),
          catchError((e) => of(AbsenceApiActions.DeleteAbsenceError(e.message)))
        )
      )
    );
  });

  deleteAbsencePromt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditAbsencePageActions.DeleteAbsence),
      exhaustMap((action) => {
        const confirmationResult = new Subject<boolean>();

        let message = this.translationService.translate('delete-description');
        if (action.isExported)
          message += '<br><br>' + this.translationService.translate('timeManagement-item-is-exported');

        this.confirmationService.confirm({
          header: this.translationService.translate('delete'),
          message: message,
          acceptLabel: this.translationService.translate('delete'),
          rejectLabel: this.translationService.translate('cancel'),
          icon: 'pi pi-exclamation-triangle',
          acceptButtonStyleClass: 'p-button-danger',
          rejectButtonStyleClass: 'p-button-secondary',
          acceptIcon: 'fal fa-trash-alt',
          accept: () => {
            confirmationResult.next(true);
            confirmationResult.complete();
          },
          reject: () => {
            confirmationResult.next(false);
            confirmationResult.complete();
          },
        });
        return confirmationResult.asObservable();
      }),
      map((result: boolean) => {
        if (result === false) {
          return EditAbsencePageActions.DeleteAbsenceCanceled();
        }
        return EditAbsencePageActions.DeleteAbsenceConfirmed();
      })
    );
  });

  editGoBack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditAbsencePageActions.GoBack),
        concatLatestFrom(() => this.store.select(selectRoutedAbsenceId)),
        map(([action, absenceId]) => {
          this.router.navigate(['/time/my/absence/' + absenceId]);
        })
      );
    },
    { dispatch: false }
  );

  goBackToTemplateSelection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewAbsencePageActions.GoBack),
      concatLatestFrom(() => this.store.select(selectSelectedAbsenceType)),
      filter(([action, selectedOvertimeType]) => selectedOvertimeType !== null),
      map((action) => {
        return AbsencePageActions.resetAbsenceTypeSelection();
      })
    );
  });

  goBackToOverview$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NewAbsencePageActions.GoBack),
        concatLatestFrom(() => this.store.select(selectSelectedAbsenceType)),
        filter(([action, selectedAbsenceTypeId]) => selectedAbsenceTypeId === null),
        map((action) => {
          this.router.navigate(['/time/my/absence']);
        })
      );
    },
    { dispatch: false }
  );

  goBackHasChanges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewAbsencePageActions.GoBack),
      concatLatestFrom(() => this.store.select(selectHasAbsenceFormChanges)),
      filter(([_, hasChanges]) => hasChanges),
      exhaustMap((_) => {
        //TODO: Move to service
        const confirmationResult = new Subject<boolean>();

        this.confirmationService.confirm({
          header: this.translationService.translate('cancel'),
          message: this.translationService.translate('cancel-confirm'),
          acceptLabel: this.translationService.translate('yes'),
          rejectLabel: this.translationService.translate('no'),
          acceptButtonStyleClass: 'p-button-primary',
          rejectButtonStyleClass: 'p-button-secondary',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.store.dispatch(ApplicationActions.DiscardUnsavedChanges());
            confirmationResult.next(true);
            confirmationResult.complete();
          },
          reject: () => {
            confirmationResult.next(false);
            confirmationResult.complete();
          },
        });
        return confirmationResult.asObservable();
      }),
      map((result: boolean) => {
        if (result === false) {
          return NewAbsencePageActions.RejectCancelChanges();
        }
        return AbsencePageActions.resetAbsenceTypeSelection();
      })
    );
  });

  validateAbsence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsencePageActions.validateAbsence),
      switchMap((action) => {
        return this.absenceService.validateAbsence(action.absenceId).pipe(
          map((result) => {
            return AbsenceApiActions.ValidateAbsenceSuccess({ result: result });
          }),
          catchError((e) => of(AbsenceApiActions.ValidateAbsenceError(e.message)))
        );
      })
    )
  );

  checkForExistingAbsence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsencePageActions.PreValidateAbsence),
      concatLatestFrom(() => [
        this.store.select(selectSelectedEmployeeIds),
        this.store.select(UserSelectors.selectSelectedUserId),
      ]),
      switchMap(([action, userIds, userId]) => {
        var ids = userIds.length > 0 ? userIds : [userId];
        return this.absenceService.preValidate(action.absenceTypeId, ids, action.startDate, action.endDate).pipe(
          map((result) => {
            return AbsenceApiActions.PreValidateAbsenceSuccess({ result: result });
          }),
          catchError((e) => of(AbsenceApiActions.PreValidateAbsenceError(e.message)))
        );
      })
    )
  );

  extendAbsence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsencePageActions.ExtendAbsence, ManageAddAbsencePageActions.ExtendAbsence),
      switchMap((action) =>
        this.absenceService.extendAbsence(action.absenceId, action.startDate, action.endDate).pipe(
          map((absence: AbsenceModel) => {
            return AbsenceApiActions.ExtendAbsenceeSuccess({
              absence: absence,
            });
          }),
          catchError((e) => of(AbsenceApiActions.ExtendAbsenceError(e.message)))
        )
      )
    )
  );

  navigateAfterExtendSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AbsenceApiActions.ExtendAbsenceeSuccess),
        concatLatestFrom(() => [
          this.store.select(selectSelectedFollowUpId),
          this.store.select(UserSelectors.selectUser)
        ]),
        map(([action, followUpId, user]) => {
          if(followUpId) {
            this.router.navigate(['/sick-leave/follow-up', followUpId]);
          } else {
            if(action.absence.EmployeeId == user?.EmployeeId) {
              this.router.navigate(['/time/my/absence', action.absence.AbsenceId]);
            } else {
              this.router.navigate(['/time/manage/absence', action.absence.AbsenceId]);
            }
          }
        })
      );
    },
    { dispatch: false }
  );

  navigateAfterSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AbsencePageActions.GoToAbsenceOverView,
          AbsenceApiActions.UpdateAbsenceSuccess,
          AbsenceApiActions.DeleteAbsenceSuccess,
          AbsenceApiActions.registerAbsenceSuccess
        ),
        map((action) => {
          this.router.navigate(['/time/my/absence']);
        })
      );
    },
    { dispatch: false }
  );
}
