import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { AbsenceType } from "../my-time/absence/models/absence-type.model";
import { selectAbsenceTypes } from "../state/time-common/selectors/time-common.selectors";
import { TranslationDataService } from "@mentor-one-ui/core/services/translation-data.service";


@Pipe({
    name: 'minutesToHours',
    pure: false,
    standalone: true
})
export class MinutesToHoursPipe implements PipeTransform {


  constructor(public translateService: TranslationDataService) {

  }

  transform(totalMinutes: number): string {
    const isNegative = totalMinutes < 0;
    const absTotalMinutes = Math.abs(totalMinutes);

    const hours = Math.floor(absTotalMinutes / 60);
    const remainingMinutes = absTotalMinutes % 60;

    if(remainingMinutes === 0) return `${isNegative ? '-' : ''}${hours} ${this.translateService.translate('hours')}`;
    return `${isNegative ? '-' : ''}${hours} ${this.translateService.translate('hours')} ${this.translateService.translate('and')} ${remainingMinutes} ${this.translateService.translate('minutes')}`;
  }
}
