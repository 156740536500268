import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MentorAdminEmployeeListModel } from '@mentor-one-ui/core/models/employee/MentorAdminEmployeeListModel';
import { AdminEmployeesApiActions } from '@mentor-one-ui/mentor-admin/admin-employees/state/actions/admin-employees-api.actions';
import { AdminEmployeesPageActions } from './actions/admin-employees-page.actions';

export const employeesFeatureKey = 'employees';


export const adapter: EntityAdapter<MentorAdminEmployeeListModel> = createEntityAdapter<MentorAdminEmployeeListModel>({
  sortComparer: false,
  selectId: (s) => s.EmployeeId
});
export interface State extends EntityState<MentorAdminEmployeeListModel> {
  search: string;
}

export const initialState: State = adapter.getInitialState({
  search: ''
});

export const adminEmployeeReducer = createReducer(
  initialState,
  on(AdminEmployeesApiActions.SearchSuccess, (state, action) => adapter.setAll(action.employees, state)),
  on(AdminEmployeesApiActions.EmployeeListSuccess, (state, action) => adapter.setAll(action.employees, state)),

  on(AdminEmployeesPageActions.SetSearchQuery, (state, { search }) => {
    return {
      ...state,
      search: search
    }
  })
);


