
import { Injectable } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { TranslationDataService } from '../services/translation-data.service';
import { ApplicationActions } from '../state/application/application.actions';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard  {
  constructor(
    private store: Store,
    private confirmationService: ConfirmationService,
    private translationService: TranslationDataService
  ) {}
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (component.canDeactivate()) {
      this.store.dispatch(ApplicationActions.DiscardUnsavedChanges());
      return true;
    } else {
      const confirmationResult = new Subject<boolean>();
      this.confirmationService.confirm({
        header: this.translationService.translate('cancel'),
        message: this.translationService.translate('cancel-confirm'),
        acceptLabel: this.translationService.translate('yes'),
        rejectLabel: this.translationService.translate('no'),
        acceptButtonStyleClass: 'p-button-primary',
        rejectButtonStyleClass: 'p-button-secondary',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(ApplicationActions.DiscardUnsavedChanges());
          confirmationResult.next(true);
          confirmationResult.complete();
        },
        reject: () => {
          confirmationResult.next(false);
          confirmationResult.complete();
        },
      });

      return confirmationResult.asObservable();
    }
  }
}
