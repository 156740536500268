import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Select } from 'primeng/select';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import {
  AdminBalanceModel,
  OvertimeAllocationModel,
  UpdateEmployeeBalanceAllocationModel,
  UpdateEmployeeBalanceRequestModel,
} from '../../models/admin-balance.model';
import { AdminBalancePageActions } from '../../actions/admin-balance-page.actions';

import {
  selectIsSavingAllocatedTime,
  selectOvertimeAllocations,
  selectSelectedEmployeeAdminBalances,
} from '../../state/selectors';
import { AvatarModule } from 'primeng/avatar';
import { EmployeeAvatarComponent } from '@mentor-one-ui/core/components/employee-avatar/employee-avatar.component';

import { InputNumberModule } from 'primeng/inputnumber';
import { SkeletonModule } from 'primeng/skeleton';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { InputTextModule } from 'primeng/inputtext';
import * as _ from 'underscore';
import { EmployeeBalanceDetailPageActions } from './actions/employee-balance-detail.page.actions';
import { ToastModule } from 'primeng/toast';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { EmployeeAvatarNameComponent } from '@mentor-one-ui/employees/components/employee-avatar-name/employee-avatar-name.component';
import { EmojiComponent } from '@mentor-one-ui/core/components/emoji/emoji.component';
import { CollapsableInfoPanelComponent } from '@mentor-one-ui/core/components/collapsable-info-panel/collapsable-info-panel.component';
import { ButtonModule } from 'primeng/button';
import { AdminBalanceApiActions } from '../../actions/admin-balance-api.actions';
import { ConfirmationService } from 'primeng/api';
import { SelectButtonModule } from 'primeng/selectbutton';
import { selectOvertimeTemplates, selectOvertimeTemplatesTimeForTimeOffIsActive } from '@mentor-one-ui/user-organization/components/settings/state/settings.selectors';
import { TabsModule } from 'primeng/tabs';
import { DisallowBalanceAllocationPipe } from '../../../pipes/allow-balance-allocation.pipe';
import { MinutesToHoursPipe } from '../../../pipes/minutes-to-hours.pipe';
import { TranslatePipe } from '../../../../core/pipes/translate.pipe';
import { DefaultButtonsComponent } from '../../../../core/components/default-buttons/default-buttons.component';
import { TimeManagementTypeCode } from '@mentor-one-ui/time/my-time/absence/models/time-management-type-code.enum';
import { ConfirmDialog } from 'primeng/confirmdialog';

type TimeOffDirection = 'add' | 'subtract';

@Component({
  selector: 'mo-balance-detail',
  standalone: true,
  styleUrls: ['./employee-balance-detail.component.scss'],
  templateUrl: './employee-balance-detail.component.html',
  imports: [
    CommonModule,
    FormsModule,
    Select,
    DisallowBalanceAllocationPipe,
    MinutesToHoursPipe,
    TranslatePipe,
    AvatarModule,
    DefaultButtonsComponent,
    InputNumberModule,
    SkeletonModule,
    InputTextModule,
    ToastModule,
    PanelModule,
    TableModule,
    EmployeeAvatarNameComponent,
    EmojiComponent,
    CollapsableInfoPanelComponent,
    TabsModule,
    ButtonModule,
    SelectButtonModule,
    ConfirmDialog,
  ],
  providers: [ConfirmationService],
})
export class EmployeeBalanceDetailComponent implements OnInit, OnDestroy {
  public ngDestroyed$ = new Subject();
  compensatoryTimeOffHour: number = 0;
  compensatoryTimeOffMinutes: number = 0;
  employeeId: number;
  selectedYear: number;
  availableYears: number[];
  adminBalances$: Observable<AdminBalanceModel[] | undefined>;
  isSaving$: Observable<boolean> = this.store.select(selectIsSavingAllocatedTime);
  isLoading: boolean = true;
  btnCanSave: boolean = false;
  initialValue: AdminBalanceModel[];
  balances: AdminBalanceModel[];
  isSaving: boolean;
  activeIndex: number = 0;
  compensatoryTimeOffComment: string;
  compensatoryTimeOffDirection: TimeOffDirection;
  compensatoryTimeOffDirectionOptions: { label: string; value: TimeOffDirection; icon: string }[];
  overtimeAllocations: Observable<OvertimeAllocationModel[]> = this.store.select(selectOvertimeAllocations);
  showTimeForTimeOffWarning$: Observable<boolean> = this.store.select(selectOvertimeTemplatesTimeForTimeOffIsActive);
  previousYear: number;

  TimeManagementTypeCode = TimeManagementTypeCode;

  constructor(
    private store: Store,
    public config: DynamicDialogConfig,
    private translationService: TranslationDataService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.adminBalances$ = this.store.select(selectSelectedEmployeeAdminBalances).pipe(
      filter((t) => t?.length > 0 && !this.isSaving),
      tap((t) => {
        this.balances = t;
        this.initialValue = t.map((x) => Object.assign({}, x));
        this.canSave(t);
      })
    );

    this.employeeId = parseInt(this.config.data?.employeeId);
    this.availableYears = this.config.data?.availableYears;
    this.selectedYear = this.config.data?.selectedYear;

    this.previousYear = this.selectedYear - 1;

    this.compensatoryTimeOffDirectionOptions = [
      {
        label: this.translationService.translate('add-time-off'),
        value: 'add',
        icon: 'fal fa-plus',
      },
      {
        label: this.translationService.translate('subtract-time-off'),
        value: 'subtract',
        icon: 'fal fa-minus',
      },
    ];
  }

  saveCompensatoryTimeOff() {
    if (this.compensatoryTimeOffHour == 0 && this.compensatoryTimeOffMinutes == 0) return;

    let minutes = this.compensatoryTimeOffHour * 60 + this.compensatoryTimeOffMinutes;
    if (this.compensatoryTimeOffDirection === 'subtract') {
      minutes = -minutes;
    }
    this.store.dispatch(
      AdminBalanceApiActions.addOvertimeAllocation({
        employeeId: this.employeeId,
        minutes: minutes,
        comment: this.compensatoryTimeOffComment,
      })
    );

    this.compensatoryTimeOffHour = 0;
    this.compensatoryTimeOffMinutes = 0;
    this.compensatoryTimeOffComment = '';
  }

  getTime(minutes: number) {
    if (minutes < 0) {
      let hours = Math.ceil(minutes / 60);
      let min = minutes % 60;
      return `${hours}t ${min}m`;
    } else {
      let hours = Math.floor(minutes / 60);
      let min = minutes % 60;
      return `${hours}t ${min}m`;
    }
  }

  deleteConfirmed(overtimeAllocation: OvertimeAllocationModel) {
    this.store.dispatch(
      AdminBalanceApiActions.deleteOvertimeAllocation({ employeeId: this.employeeId, id: overtimeAllocation.Id })
    );
  }

  deleteOvertimeAllocation(event: any, overtimeAllocation: OvertimeAllocationModel) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translationService.translate('delete-question'),
      header: this.translationService.translate('delete'),
      acceptLabel: this.translationService.translate('delete'),
      rejectLabel: this.translationService.translate('cancel'),
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary',
      acceptIcon: 'fal fa-trash-alt',
      accept: () => {
        this.deleteConfirmed(overtimeAllocation);
      },
      reject: () => {},
    });
  }

  canSave(balances: AdminBalanceModel[]) {
    this.btnCanSave = !_.isEqual(this.initialValue, balances);
  }

  onYearChange(event: any) {
    let year = event.value;
    this.previousYear = year - 1;
    this.store.dispatch(EmployeeBalanceDetailPageActions.ChangeYear({ year: year, employeeId: this.employeeId }));
  }

  save() {
    let updateedBalance: UpdateEmployeeBalanceRequestModel = <UpdateEmployeeBalanceRequestModel>{};

    updateedBalance.Year = this.selectedYear;
    updateedBalance.Allocations = [];

    this.initialValue.forEach((i) => {
      let different = this.balances.find(
        (ba) =>
          i.OrganizationAbsenceMapId === ba.OrganizationAbsenceMapId &&
          (i.AllocatedDays !== ba.AllocatedDays || i.Comment !== ba.Comment || i.TransferDaysIn !== ba.TransferDaysIn)
      );

      if (different != null && different != undefined) {
        let allocation = <UpdateEmployeeBalanceAllocationModel>{};
        allocation.OrganizationAbsenceMapId = different.OrganizationAbsenceMapId!;
        allocation.AllocatedDays = different.AllocatedDays;
        allocation.Comment = different.Comment;
        allocation.TransferDaysIn = different.TransferDaysIn;
        updateedBalance.Allocations.push(allocation);
      }
    });

    this.store.dispatch(
      AdminBalancePageActions.updateEmployeeBalance({
        employeeId: this.employeeId,
        updatemodel: updateedBalance,
      })
    );
  }

  goBack() {
    if (!_.isEqual(this.initialValue, this.balances)) {
      this.confirmationService.confirm({
        message: this.translationService.translate('cancel-confirm-changes'),
        header: this.translationService.translate('cancel'),
        acceptLabel: this.translationService.translate('yes'),
        rejectLabel: this.translationService.translate('no'),
        acceptButtonStyleClass: 'p-button-primary',
        rejectButtonStyleClass: 'p-button-secondary',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(AdminBalancePageActions.CloseEmployeeModal());
        },
        reject: () => {},
      });
    } else {
      this.store.dispatch(AdminBalancePageActions.CloseEmployeeModal());
    }
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(null);
    this.ngDestroyed$.complete();
  }
}
