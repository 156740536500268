import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { createAction, props } from '@ngrx/store';

export const AbsenceSettingsPageActions = {
  Enter: createAction('[AbsenceSettingsPage] Enter'),
  openAbsenceCodeModal: createAction(
    '[AbsenceSettingsPage] Open absence code modal',
    props<{ timeManagementType: TimeManagementType }>()
  ),
  ModalWasClosed: createAction('[AbsenceSettingsPage] Modal was closed'),
  openAbsenceModal: createAction('[AbsenceSettingsPage] Open absence modal'),
  openSelfCertificationModal: createAction('[AbsenceSettingsPage] Open self certification modal'),
};
