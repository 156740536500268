import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { SafeHtmlPipe } from "@mentor-one-ui/core/pipes/safe-html.pipe";

import { FrontpageActions } from "@mentor-one-ui/core/state/frontpage/frontpage.actions";
import { selectSystemMessageList } from "@mentor-one-ui/core/state/frontpage/frontpage.selectors";
import { Store } from "@ngrx/store";
import { ButtonModule } from "primeng/button";
import { MessageModule } from "primeng/message";
import { ToastModule } from "primeng/toast";
import { Observable } from "rxjs";

@Component({
  selector: 'mo-system-message-list',
  styleUrls: ['./system-message-list.component.scss'],
  standalone: true,
  template: `
    <div *ngFor="let message of systemMessageList$ | async" class="message-list">
      <p-message [severity]="message.severity" closable (onClose)="close(message)">
        <div class="message-item">
          <i class="info {{message.icon}}"></i>
          <div class="message-content">
            <div class="message-title">
              <h2 class="h4">{{message.text}}</h2>
              <button pButton 
                icon="fa fa-chevron-down"
                (click)="toggleInfo()"
                class="info-toggler p-button-sm p-button-text mobile-only"
                [ngClass]="{'upside-down': infoToggled}">
              </button>
            </div>
            <p [ngClass]="{'mobile-hidden': !infoToggled}" 
              [innerHTML]="message.detail | safeHtml"></p>
          </div>
        </div>
      </p-message>
    </div>
    `,
  imports: [
    CommonModule,
    MessageModule,
    ToastModule,
    ButtonModule,
    SafeHtmlPipe,
],
})
export class SystemMessageListComponent {
  systemMessageList$: Observable<any> = this.store.select(selectSystemMessageList);
  infoToggled: boolean = false;

  constructor(private store: Store) { }

  toggleInfo(){
    this.infoToggled = !this.infoToggled;
  }

  close(message: any) {
    this.store.dispatch(FrontpageActions.SetSystemMessageAsClosed({ id: parseInt(message.key!) }));
  }
}