import { SystemMessageModel, SystemMessageTypeEnum } from '@mentor-one-ui/core/models/system-messages.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromApplication from './frontpage.reducer';
import { EmployeeListSelectors } from '@mentor-one-ui/employees/state/selectors/employee-list.selectors';
import {
  selectAbsenceLoadError,
  selectAbsencesLoaded,
  selectAllAbsences,
} from '@mentor-one-ui/time/my-time/absence/state/absence.selectors';
import {
  selectAllOvertimes,
  selectOvertimeLoadError,
  selectOvertimeLoaded,
} from '@mentor-one-ui/time/my-time/overtime/state/overtime.selectors';
import { TimeManagementStatusEnum } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementStatusEnum';
import { Signal } from '@angular/core';

export const selectFrontpageState = createFeatureSelector<fromApplication.State>('frontpage');

export const selectSystemMessageList = createSelector(selectFrontpageState, (state) => {
  return state.systemMessageList
    .filter((sm: SystemMessageModel) => {
      return sm.IsClosed == false;
    })
    .map((systemMessage: SystemMessageModel) => {
      return {
        severity: getSeverity(systemMessage.MessageType),
        text: systemMessage.Title,
        detail: systemMessage.Description,
        icon: getIcon(systemMessage.MessageType),
        closable: true,
        key: '' + systemMessage.Id,
      };
    });
});

export const selectManagedRegistrationsViewModel = createSelector(
  selectAllAbsences,
  selectAllOvertimes,
  selectAbsenceLoadError,
  selectOvertimeLoadError,
  selectAbsencesLoaded,
  selectOvertimeLoaded,
  (absences, overtimes, absenceError, overtimeError, absenceLoaded, overtimeLoaded) => {
    let a = absences.filter((absence) => absence.Status !== TimeManagementStatusEnum.Pending);
    let b = overtimes.filter((overtime) => overtime.Status !== TimeManagementStatusEnum.Pending);

    return {
      items: [...a, ...b].sort((a, b) => new Date(b.ProcessedDateTime!).getTime() - new Date(a.ProcessedDateTime!).getTime()).slice(0, 3),
      hasError: absenceError || overtimeError,
      loaded: absenceLoaded && overtimeLoaded,
    };
  }
);

export const selectManageTimeListFrontPage = createSelector(selectFrontpageState, (state) => state.manageList);

export const selectHasManageListError = createSelector(
  selectFrontpageState,
  EmployeeListSelectors.selectEmployeesLoadError,
  (state, employeeLoadError) => state.manageListError || employeeLoadError
);

export const selectManageTimeListFrontPageViewModel = createSelector(
  selectManageTimeListFrontPage,
  selectHasManageListError,
  (manageList, hasError) => {
    return {
      manageList,
      itemCount: manageList?.Count,
      hasError,
    };
  }
);

const selectSelectedManageItemId = createSelector(selectFrontpageState, (state) => state.selectedManageItemId);

export const selectSelectedManageItem = createSelector(selectFrontpageState, selectSelectedManageItemId, (state, id) =>
  state.manageList?.Items?.find((item) => item.Id === id)
);

export const selectFrontpageIsSaveing = createSelector(selectFrontpageState, (state) => state.isSaving);
export const selectFrontpageManageListLoading = createSelector(
  selectFrontpageState,
  (state) => state.manageListLoading
);
export const selectDailySummary = createSelector(selectFrontpageState, (state) => state.dailySummary);

export const selectHasTodayLoadError = createSelector(
  selectFrontpageState,
  EmployeeListSelectors.selectEmployeesLoadError,
  (state, employeeLoadError) => state.todayError || employeeLoadError
);

export const selectFollowUpListFrontPage = createSelector(selectFrontpageState, (state) => state.followUpList);

function getSeverity(messageType: SystemMessageTypeEnum): 'info' | 'warn' | 'error' | 'success' {
  switch (messageType) {
    case SystemMessageTypeEnum.Information:
      return 'info';
    case SystemMessageTypeEnum.PlannedMaintenance:
      return 'warn';
    case SystemMessageTypeEnum.ErrorMessage:
      return 'error';
    default:
      return 'info';
  }
}
function getIcon(MessageType: SystemMessageTypeEnum): string | undefined {
  switch (MessageType) {
    case SystemMessageTypeEnum.Information:
      return 'fal fa-info-circle';
    case SystemMessageTypeEnum.ErrorMessage:
      return 'fal fa-times-circle';
    case SystemMessageTypeEnum.PlannedMaintenance:
      return 'fal fa-exclamation-triangle';
    default:
      return undefined;
  }
}
