import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {}
  switchTheme(darkmode: Boolean) {
    let app = this.document.querySelector('html');
    if (darkmode) {
      app?.classList.add('mentor-darkmode');
    } else {
      app?.classList.remove('mentor-darkmode');
    }
  }
}
