import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonBarButton } from '@mentor-one-ui/core/models/ButtonBar';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from "../button/button.component";

@Component({
    selector: 'mo-button-bar',
    styleUrls: ['./button-bar.scss'],
    template: `
    <div class="button-bar">
      <span *ngFor="let button of buttonList">
        <span *ngIf="!button.routerLink && button.visible">
          <mo-button
            [id]="button.id"
            (click)="handleButtonClick(button)"
            (routerLink)="(button.routerLink)"
            [icon]="button.icon || ''"
            [iconPos]="button.iconPos || iconPos"
            [class]="button.class"
            [loading]="(button?.isSaveButton && isSaving)!"
            [pTooltipTerm]="button.pTooltipTerm || ''"
            [labelTerm]="button.labelTerm"
            [disabled]="(button.disabled || isSaving || (button?.isSaveButton && !canSave))!"
            [isSaving]="button?.isSaveButton ? isSaving : false"
          ></mo-button>
        </span>
        <a pButton *ngIf="button.visible && button.routerLink" [routerLink]="button.routerLink">{{ button.labelTerm }}</a>
      </span>
    </div>
  `,
    standalone: true,
    imports: [
    CommonModule,
    ButtonModule,
    RouterLink,
    ButtonComponent
],
})
export class ButtonBarComponent implements OnInit, OnChanges {
  @Input() buttonList: ButtonBarButton[];
  @Input() isSaving: boolean;
  @Input() canSave: boolean = true;
  @Input() pTooltipTerm: string;
  @Output() buttonBarClick: EventEmitter<ButtonBarButton> = new EventEmitter<ButtonBarButton>();
  iconPos: 'left' | 'right' = 'left';

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buttonList) {
      this.buttonList = this.buttonList || [];
    }
  }

  ngOnInit(): void {
  }

  handleButtonClick(button: ButtonBarButton) {
    this.buttonBarClick.emit(button);
  }
}
