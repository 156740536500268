import { createAction, props } from '@ngrx/store';

export const contactPageActions = {
  enter: createAction('[Contact Page] Enter'),
  openContactModal: createAction('[Contact List] Open contact modal'),
  contactModalClosed: createAction('[Contact List] Contact modal closed'),

  editContact: createAction('[Contact List] Edit contact', props<{ payload: number }>()),
  editNewContact: createAction('[Contact List] Edit new contact'),

  setFilterTag: createAction('[Contact List] Set selected tag', props<{ payload: string }>()),
}
