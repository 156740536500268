import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { PanelModule } from 'primeng/panel';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

@Component({
  selector: 'mo-collapsable-info-panel',
  styleUrls: ['./collapsable-info-panel.component.scss'],
  standalone: true,
  imports: [CommonModule, PanelModule, SafeHtmlPipe],
  template: `
    <p-panel
      [toggleable]="true"
      [collapsed]="collapsed"
      (collapsedChange)="collapsedChanged($event)"
      toggler="header"
      styleClass="collapsable-info-panel"
      collapseIcon="fal fa-chevron-down"
      expandIcon="fal fa-chevron-up"
    >
      <ng-template pTemplate="header">
        <div class="u-flex">
          <i class="fal {{ iconClass }}"></i>
          <span>{{ header }}</span>
        </div>
      </ng-template>
      <p *ngIf="infoText">
        {{ infoText }}
      </p>
      <div *ngIf="html" [innerHTML]="html | safeHtml"></div>
      <ng-content *ngIf="!collapsed"></ng-content>
    </p-panel>
  `,
})
export class CollapsableInfoPanelComponent implements OnInit {
  @Input() header: string;
  @Input() infoText: string;
  @Input() question: boolean = false;
  @Input() html: string | null;
  @Input() collapsed: boolean = true;
  iconClass: string;
  showContent: boolean;
  
  ngOnInit() {
    this.iconClass = this.question ? 'fa-question-circle' : 'fa-info-circle';    
  }

  collapsedChanged(event: any) {
    this.collapsed = event;    
  }
}
