import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GroupOrganizationTypeEnum } from '@mentor-one-ui/core/models/enums';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';

import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';
import { UserActions } from '@mentor-one-ui/core/state/user/user.actions';
import { Store } from '@ngrx/store';
import { Button, ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialog } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButton } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';

import * as _ from 'underscore';
import { OrganizationDetailsApiActions } from '@mentor-one-ui/mentor-admin/admin-organizations/state/actions/organization-details-api.actions';
import { AccessSelector } from '@mentor-one-ui/core/state/access/access.selector';

import { TranslatePipe } from '../../pipes/translate.pipe';
import { DefaultButtonsComponent } from '../default-buttons/default-buttons.component';
import { ScrollShadowDirective } from '../../directives/scroll-shadow.diractive';
import { IconField } from 'primeng/iconfield';
import { InputIcon } from 'primeng/inputicon';

@Component({
  selector: 'app-select-organization-modal',
  standalone: true,
  styleUrls: ['./select-organization-modal.component.scss'],
  template: `
    <div class="content" scrollshadow>
      <i class="mainIcon {{ getIcon(currentOrganization) }}"></i>
      <h2>{{ currentOrganization?.DisplayName }}</h2>
      <p class="current">{{ 'current-organization' | translate | async }}</p>
      <p>{{ 'change-organization-info' | translate | async }}</p>
      <div class="search p-input-icon-right ml-auto">
        <p-iconfield>
          <input
            #searchInput
            pInputText
            type="text"
            [(ngModel)]="searchText"
            (input)="search()"
            placeholder="{{ 'search' | translate | async }}"
          />
          <p-inputicon styleClass="pi pi-search" />
        </p-iconfield>
      </div>
      <p-scrollPanel [style]="{ width: '100%', height: '250px' }" styleClass="custom-scrollbar">
        <ul class="organization-list">
          <li
            *ngFor="let org of availableOrganizationsFilter"
            class="{{ selectedOrganization?.OrganizationId == org.OrganizationId ? 'selected' : '' }}"
          >
            <div class="org-item">
              <div class="org-name" (click)="selectOrg(org)">
                <p-radioButton
                  [inputId]="'' + org.OrganizationId"
                  name="org"
                  [value]="org"
                  [(ngModel)]="selectedOrganization"
                ></p-radioButton>
                <i class="{{ getIcon(org) }}" style="margin-left: 4px"></i>
                <label [for]="org.OrganizationId" class="ml-2">{{ org.DisplayName }}</label>
                <i class="fas fa-user-headset" style="margin-left: 4px" *ngIf="org.IsSupportLogin"></i>
                <i *ngIf="org.IsSupportLogin" style="margin-left: 4px">{{ org.Expires }}</i>
              </div>
              <a
                class="logout"
                *ngIf="org.IsSupportLogin && this.isSystemAdmin"
                (click)="logoutFromSupportOrganization(org)"
                >{{ 'logout' | translate | async }}</a
              >
            </div>
          </li>
        </ul>
      </p-scrollPanel>
    </div>
    <div class="p-dialog-footer p-dialog-footer--sticky">
      <mo-default-buttons
        [showDelete]="false"
        [canSave]="selectedOrganization != null"
        [isSaving]="false"
        [saveLabel]="getSaveLabelText()"
        saveIcon="fal fa-exchange"
        (cancel)="close()"
        (save)="changeOrganization(selectedOrganization?.OrganizationId!)"
      ></mo-default-buttons>
    </div>
  `,
  imports: [
    CommonModule,
    FormsModule,
    TranslatePipe,
    DynamicDialog,
    InputTextModule,
    ScrollPanelModule,
    ButtonModule,
    IconField,
    InputIcon,
    DefaultButtonsComponent,
    RadioButton,
    ScrollShadowDirective,
  ],
})
export class SelectOrganizationModalComponent {
  organizations: UserOrganizationModel[];
  availableOrganizationsAll: UserOrganizationModel[];
  availableOrganizationsFilter: UserOrganizationModel[];
  currentOrganization: UserOrganizationModel | undefined;
  selectedOrganization: UserOrganizationModel | null = null;
  selectOrgText: string = this.translationService.translate('select-organization');
  searchText: string;
  isSystemAdmin: boolean;

  constructor(
    private config: DynamicDialogConfig,
    private translationService: TranslationDataService,
    private store: Store
  ) {
    this.organizations = this.config.data.organizations;

    this.currentOrganization = this.organizations.find((org) => org.IsCurrentOrganization);
    this.createAvailableOrganizationsMenu();

    this.store.select(AccessSelector.selectIsSystemAdmin).subscribe((isSystemAdmin) => {
      this.isSystemAdmin = isSystemAdmin;
    });
  }

  flattenOrganizationTree(organization: UserOrganizationModel): UserOrganizationModel[] {
    let flattenedOrganizations: UserOrganizationModel[] = [];
    flattenedOrganizations.push(organization);
    organization.ChildOrganizations.forEach((childOrg) => {
      flattenedOrganizations = flattenedOrganizations.concat(this.flattenOrganizationTree(childOrg));
    });

    return flattenedOrganizations;
  }

  selectOrg(org: UserOrganizationModel) {
    this.selectedOrganization = org;
  }

  getSaveLabelText(): string {
    return this.selectedOrganization != null
      ? this.translationService.translate('change-to') + ' ' + this.selectedOrganization.DisplayName
      : this.selectOrgText;
  }

  logoutFromSupportOrganization(org: UserOrganizationModel) {
    this.store.dispatch(OrganizationDetailsApiActions.SupportLogoutRequest({ sid: org.Sid! }));
    this.organizations = this.organizations.filter(
      (organization) => organization.OrganizationId !== org.OrganizationId
    );
    this.createAvailableOrganizationsMenu();
  }

  createAvailableOrganizationsMenu() {
    let tempValue: UserOrganizationModel[] = [];
    this.organizations?.forEach((org) => {
      let childrenItems: UserOrganizationModel[] = [];

      childrenItems = this.flattenOrganizationTree(org);
      childrenItems.forEach((childOrg) => {
        tempValue.push(childOrg);
      });
    });

    let sortedArray = _.chain(tempValue)
      .sortBy('DisplayName')
      .sortBy((s) => {
        if (s.OrganizationId == 1) return 1;

        if (s.IsSupportLogin) return 2;

        return 3;
      })
      .value();

    this.availableOrganizationsAll = this.availableOrganizationsFilter = sortedArray;
  }

  changeOrganization(id: number | null) {
    if (id == null) return;
    this.close();
    this.store.dispatch(UserActions.switchOrganzation({ organizationId: id }));
  }

  close() {
    this.store.dispatch(ApplicationActions.CloseOrganizationSelectModal());
  }

  search() {
    if (this.searchText === '' || this.searchText === undefined) {
      this.availableOrganizationsFilter = this.availableOrganizationsAll;
    } else {
      this.availableOrganizationsFilter = this.availableOrganizationsAll.filter((org) =>
        org.DisplayName.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
  }

  getIcon(org: UserOrganizationModel | undefined): string {
    if (org?.OrganizationId === 1) {
      return 'fal fa-city';
    }

    switch (org?.GroupOrganizationType) {
      case GroupOrganizationTypeEnum.GroupOrganization:
      case GroupOrganizationTypeEnum.ServiceProvider:
        return 'fal fa-building';
      case GroupOrganizationTypeEnum.Organization:
        return 'fal fa-home';
      default:
        return 'pi pi-question';
    }
  }
}
