import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EmployeeAvatarComponent } from '@mentor-one-ui/core/components/employee-avatar/employee-avatar.component';

import { EmployeeFullNameFromIdPipe } from '../../pipes/employee-fullname.pipe';

@Component({
  selector: 'mo-employee-avatar-name',
  standalone: true,
  imports: [
    CommonModule,
    EmployeeAvatarComponent,
    EmployeeFullNameFromIdPipe
],
  styleUrls: ['./employee-avatar-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  template: `      
    <div class="avatar-and-name">
      <mo-employee-avatar [employeeId]="employeeId" class="avatar-box"></mo-employee-avatar>
      <p>{{ employeeId | employeeFullNameFromId | async }}</p>
    </div>
  `,
})
export class EmployeeAvatarNameComponent implements OnInit {
  ngOnInit(): void {
}
  @Input() employeeId: number;
 }
