import { Component } from '@angular/core';
import { SystemEnum } from '@mentor-one-ui/core/models/enums';
import { UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';
import { Store } from '@ngrx/store';
import { NgIf, AsyncPipe } from '@angular/common';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
    selector: 'app-top-navigation',
    template: ` 
      <nav class="top-nav">
        <a class="system-link" *ngIf="this.hasKidplanModule" href="https://app.kidplan.com/" target="_blank">
          <span class="mobile-hidden">{{ 'mentor-kidplan' | translate | async }}</span>
          <span class="mobile-only">{{ 'mentor-kidplan-shortname' | translate | async }}</span>
          <img src="/assets/images/external-link-dark.svg" alt="{{ 'external-link' | translate | async }}">
        </a>
        <a class="system-link" *ngIf="this.hasHmsModule" href="https://start.pblmentor.no/" target="_blank">
          <span class="mobile-hidden">{{ 'mentor-hms' | translate | async }}</span>
          <span class="mobile-only">{{ 'mentor-hms-shortname' | translate | async }}</span>
          <img src="/assets/images/external-link-dark.svg" alt="{{ 'external-link' | translate | async }}">
        </a>
        <a class="p-button p-button-sm" href="https://support.mentorpluss.no/hjelpesenter/personal" target="_blank">
          <i class="fal fa-info-circle"></i>    
            <span>{{ 'support-pages' | translate | async }}</span>
          <img src="/assets/images/external-link.svg" alt="{{ 'external-link' | translate | async }}">
        </a>
      </nav>
  `,
    styleUrls: ['./top-navigation.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe, TranslatePipe]
})
export class TopNavigationComponent {
  public hasKidplanModule: boolean = false;
  public hasHmsModule: boolean = false;

  constructor(private store: Store) { }

  ngOnInit():void{
    this.store.select(UserSelectors.selectModuleAccess)
    .subscribe((systems) => {

      this.hasKidplanModule = systems.some((system) => system.System === SystemEnum.MentorKidplan && system.HasSystemAccess);
      this.hasHmsModule = systems.some((system) => system.System === SystemEnum.MentorHms && system.HasSystemAccess);
    });
  }
}
