import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { Store, provideStore, provideState } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { ConfirmationService, MessageService } from 'primeng/api';
import { providePrimeNG } from 'primeng/config';
import { environment } from 'src/environments/environment';
import { AuthConfigModule } from './auth/auth-config.module';
import { CurrentOrganizationInterceptor } from './core/interceptors/current-organization.interceptor';
import { ErrorIntercept } from './core/interceptors/error.interceptor';
import { GlobalErrorHandler } from './core/services/global-error.service';
import { ROOT_REDUCERS, metaReducers } from './core/state';
import { ApplicationEffects } from './core/state/application/application.effects';
import { AuthEffects } from './core/state/auth/auth.effects';
import { FrontpageEffects } from './core/state/frontpage/frontpage.effects';
import { TranslationEffects } from './core/state/translations/translation.effects';
import { UserEffects } from './core/state/user/user.effects';
import { EmployeesListEffects } from './employees/state/effects/employees-list.effects';
import { AbsenceTypesEffects } from './time/state/absence-types.effects';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import MentorPlusTheme from './MentorTheme';
import { mentorAdminFeatureKey, reducers } from './mentor-admin/state';
import { filter } from 'rxjs';
import { TranslationActions } from './core/state/translations/translation.actions';
import { TranslationSelectors } from './core/state/translations/translation.selectors';
import { selectIsUserLoaded } from './core/state/user/user.selector';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app-routing';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PendingChangesGuard } from './core/guards/pending-changes.guard';
import { CultureCodePipe } from './core/pipes/culturecode.pipe';
import { AdminBalanceEffects } from './time/admin-balance/state/effects/admin-balance.effects';
import { UserOrganizationEffects } from './user-organization/state/user-organization.effects';

export function appInitializerFactory(store: Store): Function {
  return () =>
    new Promise((resolve) => {
      store
        .select(selectIsUserLoaded)
        .pipe(filter((userLoaded) => userLoaded))
        .subscribe(() => {
          store
            .select(TranslationSelectors.selectIsLoaded)
            .pipe(filter((languageLoaded) => languageLoaded))
            .subscribe(() => resolve(true));

          store.dispatch(TranslationActions.load());
        });

      store
        .select(TranslationSelectors.selectIsLoaded)
        .pipe(filter((languageLoaded) => languageLoaded))
        .subscribe(() => resolve(true));

      store.dispatch(TranslationActions.load());
    });
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideStore(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: false,
        // strictStateSerializability: true,
        // strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    provideRouterStore(),
    provideState({ name: mentorAdminFeatureKey, reducer: reducers }),
    provideEffects([
      AuthEffects,
      ApplicationEffects,
      UserEffects,
      TranslationEffects,
      FrontpageEffects,
      AbsenceTypesEffects,
      EmployeesListEffects,
      AdminBalanceEffects,
      UserOrganizationEffects,
    ]),
    MessageService,
    DialogService,
    ConfirmationService,
    PendingChangesGuard,
    DynamicDialogConfig,
    CultureCodePipe,
    importProvidersFrom(
      !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
      AuthConfigModule
    ),
    { provide: LOCALE_ID, useValue: 'nb-NO' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Store],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CurrentOrganizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: MentorPlusTheme,
        options: {
          darkModeSelector: '.mentor-darkmode',
        },
      },
    }),
  ],
};
