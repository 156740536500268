import { selectAllemployees, selectMyEmployees } from '@mentor-one-ui/employees/state/selectors/employee.selectors';
import { selectTimeState } from '@mentor-one-ui/time/state/selectors';
import { selectTimeTypes } from '@mentor-one-ui/time/state/time-common/selectors/timeTypeSelectors';
import { createSelector } from '@ngrx/store';
import { AdminBalanceModel } from '../../models/admin-balance.model';
import { selectAllDepartments } from '@mentor-one-ui/user-organization/components/departments/state/departments.selectors';
import { adapter } from '..';
import {
  selectAbsenceTypes,
  selectOvertimeTypes,
} from '@mentor-one-ui/time/state/time-common/selectors/time-common.selectors';

export const selectAdminBalanceState = createSelector(selectTimeState, (state) => state.adminBalance);

export const selectAdminBalanceEntityState = createSelector(selectTimeState, (state) => state?.adminBalance);
export const {
  selectIds: selectBalanceIds,
  selectEntities: selectBalanceEntities,
  selectAll: selectAllBalances,
  selectTotal: selectTotalBalances,
} = adapter.getSelectors(selectAdminBalanceEntityState);

export const selectFilter = createSelector(selectAdminBalanceState, (state) => state.filter);

export const selectSelectedTypeType = createSelector(selectFilter, selectTimeTypes, (filter, timeTypes) => {
  let timeType = timeTypes.find((t) => t.selectId === filter.selectedTimeTypeId);
  if (!timeType) return null;
  return {
    id: timeType?.timeTypeId,
    timeManagementType: timeType?.timeManagementType,
    balanceDisplayType: timeType?.balanceDisplayType,
  };
});

export const selectSelectedYear = createSelector(selectFilter, (filter) => filter.selectedYear);

export const selectPreviousYear = createSelector(selectFilter, (filter) => filter.selectedYear -1);

export const selectIsLoading = createSelector(selectAdminBalanceState, (state) => state.isLoading);

export const selectHasLoadError = createSelector(selectAdminBalanceState, (state) => state.loadError);

export const selectIsSavingAllocatedTime = createSelector(selectAdminBalanceState, (state) => state.isSaving);

export const selectEmployeeBalances = createSelector(
  selectAdminBalanceState,
  selectAllBalances,
  selectFilter,
  selectAllDepartments,
  selectAllemployees,
  (state, b, filter, departments, employees) => {
    let balances: AdminBalanceModel[] = b.map((balance: AdminBalanceModel) => {
      return {
        ...balance,
        Fullname:
          employees?.find((e) => e.EmployeeId === balance.EmployeeId)?.ContactInformation?.FirstName +
          ' ' +
          employees?.find((e) => e.EmployeeId === balance.EmployeeId)?.ContactInformation?.LastName,
      };
    });

    if (filter.selectedEmployeeId != -1) {
      balances = balances.filter((balance: AdminBalanceModel) => {
        return balance.EmployeeId === filter.selectedEmployeeId;
      });
    }

    if (filter.selectedDepartmentId != -1) {
      let department = departments.find((d) => d.DepartmentId === filter.selectedDepartmentId);
      balances = balances.filter((balance: AdminBalanceModel) => {
        return department?.EmployeeIds?.some((e) => e === balance.EmployeeId);
      });
    }
    if (filter.selectedTimeTypeId) {
      balances = balances.filter((balance: AdminBalanceModel) => {
        return (
          'a' + balance.OrganizationAbsenceMapId === filter.selectedTimeTypeId! ||
          'o' + balance.OrganizationOvertimeMapId === filter.selectedTimeTypeId!
        );
      });
    }

    if (filter.selectedYear) {
      balances = balances.filter((balance: AdminBalanceModel) => {
        return balance.Year === filter.selectedYear;
      });
    }

    return balances.filter((balance: AdminBalanceModel) => employees?.find((e) => e.EmployeeId === balance.EmployeeId)?.IsCorporationUser === false);
  }
);

export const selectSelectedBalanceDisplayType = createSelector(
  selectSelectedTypeType,
  (selectedType) => selectedType?.balanceDisplayType
);

export const selectSelectedBalances = createSelector(selectAdminBalanceState, (state) => state.selectedBalances);
export const selectOvertimeAllocations = createSelector(selectAdminBalanceState, (state) => state.overtimeAllocations);

export const selectSelectedEmployeeAdminBalances = createSelector(
  selectSelectedBalances,
  selectOvertimeTypes,
  selectAbsenceTypes,
  (selectedBalances, overtimeTypes, absenceTypes) => {
    let filteredList = selectedBalances.filter((b: AdminBalanceModel) => {
      return b.BalanceDisplayType == 0 && b.ExtraTimeAbsenceTemplateIds == null;
    });

    return filteredList.map((balance: AdminBalanceModel) => {
      const timetype =
        absenceTypes.find(
          (at) => at.OrganizationAbsenceMapId == balance.OrganizationAbsenceMapId && at.IncludeInBalance
        ) ||
        overtimeTypes.find(
          (ot) => ot.OrganizationOvertimeMapId == balance.OrganizationOvertimeMapId && ot.IncludeInBalance
        );

      return {
        ...balance,
        OrgVal: balance.AllocatedDays,
        TypeEmoji: timetype?.EmojiCode,
        TypeTitle: timetype?.Title,
      };
    });
  }
);

export const selectSelectedEmployeeId = createSelector(selectFilter, (filter) => filter.selectedEmployeeId);

export const selectSelectableYears = createSelector(selectAdminBalanceState, (state) => state.selectableYears);
export const selectAdminBalanceFilter = createSelector(selectAdminBalanceState, (state) => state.filter);
