import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NgClass, CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';

@Component({
  selector: 'mo-button',
  standalone: true,
  imports: [NgClass, CommonModule, ButtonModule, TooltipModule],
  template: `
  <p-button
    [id]="id"
    [type]="type"
    (onClick)="handleClick($event)"
    (routerLink)="routerLink"
    [styleClass]="class"
    [disabled]="disabled || loading || isSaving"
    [pTooltip]="(disabled && pTooltipTerm) ? translateService.translate(pTooltipTerm) : ''"
    [tooltipPosition]="(disabled && pTooltipTerm) ? tooltipPosition : ''"
    [icon]="icon"
    [iconPos]="iconPos"
    [loading]="isSaving || loading"
    [label]="translatedLabel"
    attr.aria-label="{{ translatedLabel}}"
  >
  </p-button>`,
})
export class ButtonComponent {

  @Input() id: any;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() routerLink: string | null = null;
  @Input() class: any = 'p-button-primary';
  @Input() disabled: boolean = false;
  @Input() isSaving: boolean;
  @Input() pTooltipTerm: string = '';
  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';
  @Input() icon: string = '';
  @Input() iconPos: 'left' | 'right' = 'left';
  @Input() loading: boolean;
  @Input() ariaLabel: string = '';
  @Input() labelTerm: string = '';
  translatedLabel: string = '';

  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(public translateService: TranslationDataService) { }

  ngOnInit() {
    this.updateTranslatedLabel();
  }

  ngOnChanges(changes: SimpleChanges): void{
    if (this.labelTerm != this.translatedLabel) {
      this.updateTranslatedLabel();
    }
  }

  private updateTranslatedLabel(): void {
    this.translatedLabel = this.translateService.translate(this.labelTerm);
  }

  handleClick(event: Event) {
    if (!this.loading && !this.disabled) {
      this.onClick.emit(event);
    }
  }
}
