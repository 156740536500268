import { Injectable } from "@angular/core";
import { TranslationDataService } from "@mentor-one-ui/core/services/translation-data.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { ConfirmationService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { catchError, map, of, switchMap } from "rxjs";
import { EmployeeDetailsApiActions, EmployeeModalActions } from "../actions/employee-details.actions";
import { EmployeeSelectors } from "../selectors/employee.selectors";
import { PictureEditorComponent } from "@mentor-one-ui/employees/components/picture-editor/picture-editor.component";
import { EmployeeService } from "@mentor-one-ui/core/services/api/employee.service";

@Injectable()
export class EmployeePictureEffects {
  constructor(
    private actions$: Actions,
    private dialogService: DialogService,
    private employeeService: EmployeeService,
    private confirmationService: ConfirmationService,
    private translationService: TranslationDataService,
    private store: Store
  ) { }

  dialogRef: DynamicDialogRef;

  showEditProfilePictureDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmployeeModalActions.LoadEmployeePictureEditorModal),
        concatLatestFrom(() => this.store.select(EmployeeSelectors.selectSelectedEmployee)),
        switchMap(([action, employee]) => {
          this.dialogRef = this.dialogService.open(PictureEditorComponent, {
            data: { employee },
            header: 'Redigere bilde',
            closeOnEscape: true,
            closable: false,
          });

          return this.dialogRef.onClose;
        })
      ),
    { dispatch: false }
  );

  deleteProfilePicture$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeDetailsApiActions.DeleteMyProfilePicture),
      switchMap((action) => {
        return this.employeeService.deleteEmployeeProfilePicture(action.employeeId).pipe(
          map((result) => {
            return EmployeeDetailsApiActions.DeleteMyProfilePictureSuccess({ employeeId: action.employeeId});
          }),
          catchError((e) => of(EmployeeDetailsApiActions.DeleteMyProfilePictureError(e.message)))
        );
      })
    );
  });

dialogClosed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmployeeModalActions.CloseEmployeePictureEditorModal),
        map((payload) => {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        })
      ),
    { dispatch: false }
  );
}
