import { createReducer, on } from '@ngrx/store';
import { SettingsApiActions, SettingsPageActions, SupportSettingsPageActions } from './settings.actions';
import { OrganizationsApiActions } from '@mentor-one-ui/mentor-admin/admin-organizations/state/actions/organization-list-api.actions';
import { TimeManagementTemplate } from '../models/time-management-template.model';
import { TimeManagementTypesModalActions } from '../absence-settings/time-management-types-modal/actions/time-management-types-modal.actions';
import { EmployeeImportResultModel } from '../models/suppert-settings.model';

export const featureKey = 'settings';

export interface State {
  isSaving: boolean;
  timeManagementTypes: TimeManagementTemplate[];
  loadError: boolean;
  employeeImportResult: EmployeeImportResultModel | null;
}

export const initialState: State = {
  isSaving: false,
  timeManagementTypes: [],
  loadError: false,
  employeeImportResult: null,
};

export const reducer = createReducer<State>(
  initialState,
  on(SettingsPageActions.saveSettings, 
    SettingsPageActions.saveSelfCertificationSettings,
    (state) => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(SettingsApiActions.saveSettingsSuccess, (state) => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(SettingsApiActions.saveSettingsFailure, (state) => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(TimeManagementTypesModalActions.save, (state) => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(OrganizationsApiActions.loadTimeManagementTypesSuccess, (state, action) => {
    return {
      ...state,
      timeManagementTypes: action.timeManagementTypes,
    };
  }),
  on(OrganizationsApiActions.loadTimeManagementTypesError, (state) => {
    return {
      ...state,
      loadError: true,
    };
  }),
  on(OrganizationsApiActions.updateTimeManagementTypesSuccess, (state, action) => {
    return {
      ...state,
      timeManagementTypes: action.timeManagementTypes,
      isSaving: false,
    };
  }),
  on(OrganizationsApiActions.updateTimeManagementTypesError, (state) => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(SupportSettingsPageActions.ImportEmployeesSuccess, (state, action) => {
    return {
      ...state,
      employeeImportResult: action.employees
    };
  }),
  on(SupportSettingsPageActions.ImportEmployeesError, (state, action) => {
    return {
      ...state,
      employeeImportResult: action.error
    };
  }),
  on(SupportSettingsPageActions.CloseImportEmployeesModal, (state) => {
    return {
      ...state,
      employeeImportResult: null
    };
  }),
);
