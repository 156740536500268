import { createAction, props } from '@ngrx/store';
import { ContactModel } from '@mentor-one-ui/core/models/user-organization/contact/ContactModel';

export const contactApiActions = {
  loadContacts: createAction('[Contact API] Load contacts'),
  loadContactsSuccess: createAction('[Contact API] Load contacts success', props<{ contact: ContactModel[] }>()),
  loadContactsError: createAction('[Contact API] Load contacts error', props<{ error: string }>()),

  saveContact: createAction('[Contact API] Save contact', props<{ contact: ContactModel }>()),
  saveContactSuccess: createAction('[Contact API] Save contact success', props<{ contact: ContactModel }>()),
  saveContactError: createAction('[Contact API] Save contact error', props<{ error: string }>()),

  deleteContact: createAction('[Contact API] Delete contact', props<{ contact: ContactModel }>()),
  deleteContactSuccess: createAction('[Contact API] Delete contact success', props<{ contactId: number }>()),
  deleteContactFailure: createAction('[Contact API] Delete contact failure', props<{ error: string }>()),
};
