import { TimeTypeDropdownModel } from '@mentor-one-ui/time/admin-balance/models/admin-balance-filter.model';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { createSelector } from '@ngrx/store';
import { selectAbsenceTypes, selectOvertimeTypes } from './time-common.selectors';
import {
  selectBalance,
  selectBalanceLoadError,
  selectIsLoadingBalance,
} from '@mentor-one-ui/time/my-time/balance/state/balance.selectors';
import { BalanceModel } from '@mentor-one-ui/time/my-time/balance/models/balance.model';
import { selectSelfCertificationSettings } from '@mentor-one-ui/user-organization/components/about/state/organization-details.selectors';

export const selectAbsenceTypesForBalance = createSelector(selectAbsenceTypes, (absenceTypes) => {
  return absenceTypes
    .filter((absenceType) => absenceType.IncludeInBalance)
    .map((m) => {
      return {
        selectId: 'a' + m.OrganizationAbsenceMapId,
        timeTypeId: m.OrganizationAbsenceMapId,
        timeManagementType: TimeManagementType.Absence,
        title: m.Title,
        emojiCode: m.EmojiCode,
        balanceDisplayType: m.BalanceDisplayType,
        code: m.Code
      } as TimeTypeDropdownModel;
    });
});

export const selectOvertimeTypesForBalance = createSelector(selectOvertimeTypes, (overtimeTypes) => {
  return overtimeTypes
    .filter((overtimeType) => overtimeType.IncludeInBalance)
    .map((m) => {
      return {
        selectId: 'o' + m.OrganizationOvertimeMapId,
        timeTypeId: m.OrganizationOvertimeMapId,
        timeManagementType: TimeManagementType.Overtime,
        title: m.Title,
        emojiCode: m.EmojiCode,
        balanceDisplayType: m.BalanceDisplayType,
        code: m.Code
      } as TimeTypeDropdownModel;
    });
});

export const selectTimeTypes = createSelector(
  selectOvertimeTypesForBalance,
  selectAbsenceTypesForBalance,
  (overtimeTypes, absenceTypes) => {
    return [...overtimeTypes, ...absenceTypes];
  }
);

export const selectBalancesWithTimeType = createSelector(
  selectBalance,
  selectAbsenceTypes,
  selectOvertimeTypes,
  (balances, absenceTypes, overtimeTypes) => {
    return balances.map((balance) => {
      const timetype =
        absenceTypes.find(
          (at) => at.OrganizationAbsenceMapId == balance.OrganizationAbsenceMapId && at.IncludeInBalance
        ) ||
        overtimeTypes.find(
          (ot) => ot.OrganizationOvertimeMapId == balance.OrganizationOvertimeMapId && ot.IncludeInBalance
        );

      return {
        ...balance,
        TypeEmoji: timetype?.EmojiCode,
        TypeTitle: timetype?.Title,
      } as BalanceModel;
    });
  }
);

export const selectBalanceViewModel = createSelector(
  selectBalancesWithTimeType,
  selectIsLoadingBalance,
  selectBalanceLoadError,
  selectSelfCertificationSettings,
  (balances, isLoading, loadError, hrSettings) => {
    return {
      balances,
      isLoading,
      loadError,
      isSelfCertificationLimitNumberOfOccurrences: hrSettings?.SelfCertificationLimitNumberOfOccurrences,
    };
  }
);

export const selectSelfCertificationLimitNumberOfOccurrences = createSelector(
  selectSelfCertificationSettings,
  (hrSettings) => {
    return hrSettings?.SelfCertificationLimitNumberOfOccurrences;
  }
);
