import { createAction, props } from '@ngrx/store';

const Enter = createAction('[AdminEmployees] Enter');
const GotoOrganization = createAction('[AdminEmployees] GotoOrganization', props<{ organizationId: number }>());
const Search = createAction('[AdminEmployees] Search', props<{ search: string }>());
const SetSearchQuery = createAction('[AdminEmployees] Set search query', props<{ search: string }>());
const EnterEmployeeList = createAction('[AdminEmployees] Enter Employee List');

const ResetTwoFactor = createAction('[AdminEmployees] Reset Employee Two Factor', props<{ employeeId: number }>());

export const AdminEmployeesPageActions = {
  Enter,
  GotoOrganization,
  Search,
  SetSearchQuery,
  EnterEmployeeList,
  ResetTwoFactor
};
