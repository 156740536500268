import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';
import { map, switchMap } from 'rxjs/operators';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { TranslationActions } from './translation.actions';
import { UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';
import { Translation } from 'primeng/api';
import { providePrimeNG } from 'primeng/config';

@Injectable()
export class TranslationEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private translationDataService: TranslationDataService,
  ) {}

  initTranslations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TranslationActions.load, TranslationActions.selectLanguage),
      concatLatestFrom(() => this.store$.select(UserSelectors.selectUserCultureCode)),
      switchMap(([action, selectedCultureCode]) => {
        document.querySelector('html')?.setAttribute('lang', selectedCultureCode!);
        return this.translationDataService.getTranslations(selectedCultureCode!).pipe(
          map((data) => {
            return TranslationActions.loadTranslationsSuccess();
          })
        );
      })
    );
  });

  selectPrimeLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TranslationActions.load, TranslationActions.selectLanguage),
      concatLatestFrom(() => this.store$.select(UserSelectors.selectUserCultureCode)),
      switchMap(([action, selectedCultureCode]) => {
        return this.translationDataService.getPrimeTranslations(selectedCultureCode!).pipe(
          map((t: Translation) => {
            providePrimeNG({ 
              translation: t
            });
            return TranslationActions.loadPrimeTranslationsSuccess();
          })
        );
      })
    );
  });
}
