import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { ButtonAction, ButtonBarButton } from "@mentor-one-ui/core/models/ButtonBar";
import { DepartmentLightModel } from "@mentor-one-ui/core/models/DepartmentModel";
import { EmployeeModel } from "@mentor-one-ui/core/models/employee/EmployeeModel";

import { TranslationDataService } from "@mentor-one-ui/core/services/translation-data.service";
import { EmployeeListPageActions } from "@mentor-one-ui/employees/state/actions/employee-list-page.actions";
import { EmployeeListSelectors } from "@mentor-one-ui/employees/state/selectors/employee-list.selectors";
import { departmentSelectors } from "@mentor-one-ui/user-organization/components/departments/state/departments.selectors";
import { Store } from "@ngrx/store";
import { CheckboxModule } from "primeng/checkbox";
import { Observable, tap } from "rxjs";
import { ButtonBarComponent } from "../../../core/components/button-bar/button-bar.component";
import { TranslatePipe } from "../../../core/pipes/translate.pipe";

@Component({
  selector: 'app-employee-list-mobile-filter-modal',
  styleUrls: ['./employee-list-mobile-filter-modal.component.scss'],
  template: `
  <p>{{ 'select-department' | translate | async }}</p>
  <div class="filter-detail">
    <div class="p-checkbox-field" *ngFor="let dept of (departments$ | async)">
      <p-checkbox
        (onChange)="toggleDepartment(dept.DepartmentId)"
        [ngModel]="selectedDepartmentIds.includes(dept.DepartmentId)"
        [binary]="true"
        inputId="{{dept.DepartmentId}}"
      ></p-checkbox>
      <label for="{{dept.DepartmentId}}">{{dept.Name}}</label>
    </div>
  </div>
  <div class="p-dialog-footer--sticky">
    <mo-button-bar
      [buttonList]="buttons"
      (buttonBarClick)="handleButtonClick($event)">
    </mo-button-bar>
  </div>
  `,
  standalone: true,
  imports: [
    CommonModule,
    ButtonBarComponent,
    CheckboxModule,
    FormsModule,
    TranslatePipe
],
})
export class EmployeeListMobileFilterModalComponent implements OnInit {

  buttons: ButtonBarButton[];

  filteredEmployees$: Observable<EmployeeModel[]> = this.store.select(EmployeeListSelectors.selectFilteredEmployees);
  initialNumberOfEmployees$: Observable<number> = this.store.select(EmployeeListSelectors.initialNumberOfEmployees);
  departments$: Observable<DepartmentLightModel[]> = this.store.select(departmentSelectors.selectDepartmentsForDropDown);
  selectedDepartmentIds: number[] = [];

  constructor(private translationService: TranslationDataService, private store: Store) {
    this.setUpButtons();
  }
  ngOnInit(): void {
    this.store.select(EmployeeListSelectors.selectFilter).pipe(
      tap((filter) => {
        this.selectedDepartmentIds = filter.departments;
        this.setUpButtons();
      }
      )).subscribe();
  }

  toggleDepartment(departmentId: number) {
    let tempDepartments = [...this.selectedDepartmentIds];

    if (this.selectedDepartmentIds.includes(departmentId)) {
      tempDepartments = this.selectedDepartmentIds.filter((id) => id !== departmentId);
    } else {
      tempDepartments.push(departmentId);
    }

    this.selectedDepartmentIds = tempDepartments;
    this.setUpButtons();
  }

  handleButtonClick(button: ButtonBarButton) {
    if (button.action == ButtonAction.Cancel) {
      this.store.dispatch(EmployeeListPageActions.CloseMobileFilter());
    } else if (button.action == ButtonAction.Add) {
      this.store.dispatch(EmployeeListPageActions.SetFilterDepartments({departments: this.selectedDepartmentIds}));
      this.store.dispatch(EmployeeListPageActions.CloseMobileFilter());
    } else if (button.action == ButtonAction.Reset) {
      this.store.dispatch(EmployeeListPageActions.SetFilterDepartments({ departments: [] }));
      this.store.dispatch(EmployeeListPageActions.SetFilterIsActive({ isActive: false }));
      this.store.dispatch(EmployeeListPageActions.SetFilterSearch({ search: '' }));
    }
  }

  setUpButtons() {
    this.buttons = [
    {
        labelTerm: 'save',
        action: ButtonAction.Add,
        visible: true,
        isSaveButton: true,

      } as ButtonBarButton,
      {
        labelTerm: 'clearfilter',
        action: ButtonAction.Reset,
        visible: true,
        disabled: this.selectedDepartmentIds.length === 0,
        class: 'p-button-secondary',
      } as ButtonBarButton,
      {
        labelTerm: 'cancel',
        action: ButtonAction.Cancel,
        visible: true,
        class: 'p-button-secondary',

      } as ButtonBarButton
    ];
  }
}


