import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, forkJoin, tap } from 'rxjs';
import { UserModel } from 'src/app/core/state/user/user.model';
import { MediaQuery } from './core/services/media-queries';

import { Store } from '@ngrx/store';
import { AuthSelectors } from './core/state/auth/auth.selectors';
import { ApplicationActions } from './core/state/application/application.actions';
import { checkAuth } from './core/state/auth/auth.actions';
import { ApplicationInsightsService } from '@mentor-one-ui/core/services/application-insights.service';
import { AccessSelector } from './core/state/access/access.selector';
import { UserSelectors, isSupportUserInCurrentOrganization } from './core/state/user/user.selector';
import { NgIf, AsyncPipe, registerLocaleData } from '@angular/common';
import { MainHeaderComponent } from './core/components/main-header/main-header.component';
import { SystemMessageListComponent } from './core/components/system-message-list/system-message-list.component';
import { MainNavigationComponent } from './core/components/main-navigation/main-navigation.component';
import { RouterOutlet } from '@angular/router';
import { Toast } from 'primeng/toast';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { TranslatePipe } from './core/pipes/translate.pipe';
import { MainFooterComponent } from "./core/components/main-footer/main-footer.component";
import { TopNavigationComponent } from "./core/components/top-navigation/top-navigation.component";
import localeNo from '@angular/common/locales/no';
import { PrimeNG } from 'primeng/config';
import { TranslationDataService } from './core/services/translation-data.service';

@Component({
  selector: 'app-root',
  template: `
    <button role="button" (click)="skipToMain()" class="main-shortcut-link">{{'skip-to-main-content' | translate | async}}</button>
    <div [class]="appClass()">
      <ng-container *ngIf="currentOrganizationName$ | async">
        <div class="support-user" *ngIf="(isSupportUserInCurrentOrganization$ | async) && environment != '' ? 'support-user' : ''"></div>
      </ng-container>
    <header class="app-root__header" id="app-root__header">
        <div class="layout-top-navigation layout-centered-content">
          <app-top-navigation></app-top-navigation>
        </div>
        <div class="layout-header-content layout-centered-content {{(isMentorAdmin$ | async) ? 'mentor-admin' : ''}}">
          <app-main-header></app-main-header>
        </div>
      </header>
      <h1 class="system-environment {{(isSupportUserInCurrentOrganization$ | async) ? 'support' : ''}}" (click)="hideEnviromentBar()" *ngIf="environment != ''">{{environment}}</h1>
      <section class="main-wrapper layout-centered-content">
        <mo-system-message-list></mo-system-message-list>
        <div class="main-area-grid">
        <div *ngIf="(isAuthenticated$ | async) && (selectedUser$ | async) != null  && showMenu()" class="app-root__navigation">
          <app-main-navigation></app-main-navigation>
        </div>
        <main class="app-root__main" tabindex="-1">
          <router-outlet></router-outlet>
        </main>
        </div>
      </section>
      <footer class="app-root__footer">
        <div class="layout-footer-content layout-centered-content">
          <app-main-footer></app-main-footer>
        </div>
      </footer>
      <p-toast position="top-right"></p-toast>
      <p-toast key="error" position="top-right"></p-toast>
      <p-confirmDialog [style]="{ width: 'clamp(320px, 50vw, 1024px)' }" [baseZIndex]="100000"></p-confirmDialog>
    </div>
  `,
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MainHeaderComponent,
    SystemMessageListComponent,
    MainNavigationComponent,
    RouterOutlet,
    Toast,
    ConfirmDialog,
    AsyncPipe,
    TranslatePipe,
    MainFooterComponent,
    TopNavigationComponent
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Mentor Personal';
  public loginDisplay = false;
  public currentUser$: Observable<UserModel>;
  public static screenSize = 'small';
  public isAuthenticated$: Observable<boolean>;
  public selectedUser$: Observable<UserModel | null>;
  private readonly _destroying$ = new Subject<void>();
  loginText: any;
  environment: string;
  environmentCounter: number = 0;
  isSupportUser: boolean;
  isMentorAdmin$: Observable<boolean> = this.store.select(AccessSelector.selectIsSystemAdmin);
  currentOrganizationName: string | undefined;
  isSupportUserInCurrentOrganization$: Observable<boolean | undefined>;
  currentOrganizationName$: Observable<string | undefined>;

  constructor(
    private MediaQuery: MediaQuery,
    applicationService: ApplicationInsightsService,
    private store: Store,
    private primengConfig: PrimeNG,
    private translateService: TranslationDataService
  ) {
    registerLocaleData(localeNo, 'nb-NO');

    // this.translationService.createMissingTerms(); // run to create missing language terms in english. Output in console.
    applicationService.Init();

    this.registerPrimeNgLocale();
  }

  ngOnInit(): void {

    this.MediaQuery.getScreenSize();
    this.store.dispatch(ApplicationActions.SetActiveThemeMode());

    this.store.dispatch(checkAuth());
    this.selectedUser$ = this.store.select(UserSelectors.selectUser);
    this.isAuthenticated$ = this.store.select(AuthSelectors.isAuthenticated);
    this.currentOrganizationName$ = this.store.select(UserSelectors.selectCurrentOrganizationName).pipe(tap((org) => { this.currentOrganizationName = org; }));
    this.isSupportUserInCurrentOrganization$ = this.store.select(isSupportUserInCurrentOrganization).pipe(tap((isSupportUser) => {
      this.isSupportUser = isSupportUser;

      if (this.isSupportUser) {
        this.environment = 'Innlogget som supportbruker i ' + this.currentOrganizationName;
      }
    }));

    if (window.location.hostname.includes('localhost') && this.environmentCounter <= 3) {
      this.environment = 'Utviklingsmiljø';
    } else if (window.location.hostname.includes('app-test.mentorpluss.no') && this.environmentCounter <= 3) {
      this.environment = 'Testmiljø';
    } else if (/app-mentor-one-ui-test-\d*.azurewebsites.net/.test(window.location.hostname) && this.environmentCounter <= 3) {
      this.environment = 'Testmiljø';
    } else {
      this.environment = '';
    }

  }

  hideEnviromentBar() {
    this.environmentCounter += 1;
    if (this.environmentCounter >= 3) {
      this.environment = '';
    }
  }

  registerPrimeNgLocale() {
    this.store.select(UserSelectors.selectUserCultureCode).subscribe((cultureCode: any) => {
      this.translateService.getPrimeTranslations(cultureCode).subscribe((translations: any) => {
        this.primengConfig.setTranslation(translations);
      });
    });
  }

  showMenu() {
    return MediaQuery.screenSize !== 'small' && MediaQuery.screenSize !== 'xsmall';
  }

  appClass() {
    return this.showMenu() ? 'app-root app-root--hasMenu' : 'app-root';;
  }

  skipToMain() {
    let element = document.querySelector('main') as HTMLElement;
    element?.focus();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
