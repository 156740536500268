import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { createAction, props } from '@ngrx/store';
import { SelfCertificationSettingsModel } from '../models/self-report-settings.model';
import { EmployeeImportResultModel } from '../models/suppert-settings.model';

export const SettingsPageActions = {
  saveSettings: createAction(
    '[Organization - Settings | PAGE] Save organization',
    props<{ organization: UserOrganizationModel }>()
  ),

  saveSelfCertificationSettings: createAction(
    '[Organization - Settings - SelfCertification | TAB] Save SelfCertification settings',
    props<{ settings: SelfCertificationSettingsModel }>()
  ),
};

export const SettingsDocumentationPageActions = {
  openDocumentationModal: createAction('[Organization - Settings - Documentation | TAB] Open modal'),
};

export const SettingsApiActions = {
  saveSettingsSuccess: createAction(
    '[Organization API] Save Organization Success',
    props<{ organization: UserOrganizationModel }>()
  ),
  saveSettingsFailure: createAction('[Organization API] Save organization Error', props<{ error: string }>()),
};

export const SettingsModalActions = {
  CancelModal: createAction('[Organization - Settings | MODAL] Modal Cancel Click', props<{ hasChanges: boolean }>()),
  CloseModalRejected: createAction('[Organization - Settings | MODAL] Modal Cancel Rejected'),
  CloseModalConfirmed: createAction('[Organization - Settings | MODAL] Modal Cancel Confirmed'),
  SaveModal: createAction('[Organization - Settings | MODAL] Modal Save Click', props<{ hasChanges: boolean }>()),
  CloseModal: createAction('[Organization - Settings | MODAL] Modal Close Click'),
};

export const SupportSettingsPageActions = {
  OpenImportEmployeesModal: createAction('[SupportSettingsPageActions] Open Import Employees Modal'),
  CloseImportEmployeesModal: createAction('[SupportSettingsPageActions] Close Import Employees Modal'),
  ImportEmployees: createAction('[SupportSettingsPageActions] Import Employees', props<{ file: File }>()),
  DownloadImportFile: createAction('[SupportSettingsPageActions] Download Import File'),
  DownloadImportFileSuccess: createAction('[SupportSettingsPageActions] Download Import File Success'),
  DownloadImportFileError: createAction('[SupportSettingsPageActions] Download Import File Error', props<{ error: string }>()),
  ImportEmployeesSuccess: createAction('[SupportSettingsPageActions] Import Employees Success', props<{ employees: EmployeeImportResultModel }>()),
  ImportEmployeesError: createAction('[SupportSettingsPageActions] Import Employees Error', props<{ error: EmployeeImportResultModel }>()),
};
